import React, { useState, useEffect } from 'react';
import { FaRobot, FaPaperPlane } from 'react-icons/fa';
import PropTypes from 'prop-types';

const BotPreviewCreation = ({ bot, theme, color,elementStyles, position, botData, handleElementClick }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [customization, setCustomization] = useState({})
  const toggleChat = () => setIsChatOpen(!isChatOpen);
  const botResponse = { sender: 'bot', text: 'This is a sample response.' };
  const userMessage = { sender: 'user', text: 'This is a sample message.' };
  console.log(elementStyles);
  console.log('elementStyleselementStyles');
  console.log('elementStylcccceselementStyles');
  console.log('elementStyleselementStyles');
  console.log('elementStyleselementStyles');

  // Function to send a message
  const sendMessage = () => {
    if (inputValue.trim() === '') return;

    const newMessage = { sender: 'user', text: inputValue };
    setMessages([...messages, newMessage]);
    setInputValue('');

    setIsBotTyping(true);
    // Simulate bot response
    setTimeout(() => {
      const botResponse = { sender: 'bot', text: 'This is a sample response.' };
      setMessages((currentMessages) => [...currentMessages, botResponse]);
      setIsBotTyping(false);
    }, 1000); // Simulate bot typing delay
  };

  // Set the position of the bot icon based on the 'position' prop
  const botStyle = {
    position: 'fixed',
    [position.includes('bottom') ? 'bottom' : 'top']: '20px',
    [position.includes('right') ? 'right' : 'left']: '20px',
  };

  // Set the icon color based on the 'color' prop
  const botIconStyle = {
    color: botData.color,
  };

  useEffect(() => {
    setMessages((currentMessages) => [botResponse, userMessage]);
  }, [botData.welcomeMsg]);

  // Apply the theme class based on the 'theme' prop
  const chatClass = `chat-preview ${theme} open`;
  const conversationClass = `chat-conversation ${theme} open`;

  return (
    <div className={chatClass}>
      <div className={conversationClass} style={elementStyles['conversation-background'] || {}}>
        <div className="chat-header" onClick={() => handleElementClick('chat-header')} style={elementStyles['chat-header'] || { backgroundColor: color }}>
          {bot.name ? bot.name : "Bot Name"}
        </div>
        <div className="chat-messages" onClick={() => handleElementClick('conversation-background')} >
          {messages.map((message, index) => (
            message.sender === 'user' ? (
              <div
                key={index}
                className={`message ${message.sender}-message`}
                style={elementStyles['user-message'] || { backgroundColor: color }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleElementClick('user-message');
                }}
              >
                {message.text}
              </div>
            ) : (
              <div
                key={index}
                className={`message ${message.sender}-message`}
                style={elementStyles['bot-message'] || {}}
                onClick={(e) => {
                  e.stopPropagation();
                  handleElementClick('bot-message');
                }}
              >
                {message.text}
              </div>
            )
          ))}

          {isBotTyping && (
            <div className="typing-indicator">
              <div className="dot"></div>
            </div>
          )}
        </div>
        <div className="chat-input" >
          <textarea
            type="text"
            placeholder="Type a message..."
            value={inputValue}
            style={elementStyles['text-input'] || {}}
            onChange={(e) => setInputValue(e.target.value)}
            onClick={() => handleElementClick('text-input')}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          />
          <button
            onClick={(e) => {
              handleElementClick('send-btn');
              sendMessage();
            }}
            style={elementStyles['send-btn'] || { backgroundColor: color }}
          >
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </div>
  );
};

// Define prop types for validation
BotPreviewCreation.propTypes = {
  theme: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

// Provide default props in case they are not passed
BotPreviewCreation.defaultProps = {
  theme: 'light',
  color: '#007bff',
  position: 'bottom-right',
};

export default BotPreviewCreation;
