import React, { useState } from 'react';
import '../assets/plugins/bs-stepper/css/bs-stepper.css';
import axios from 'axios';
import BotPreviewCreation from './BotPreviewCreation';
import CreateKnowledgeBaseEntry from '../knowledgeBase/CreateKnowledgeBaseEntry';
import AceEditor from 'react-ace';
import {FaPlus} from 'react-icons/fa';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';

// Import additional components for each step

const CreateBot = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [isWelcomeMsgOn, setIsWelcomeMsgOn] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

    const [botData, setBotData] = useState({
        id: '',
        name: '',
        url: '',
        llm: 'gpt4', // default value
        userId: '11',
        theme: 'light',
        status:  1,
        welcomeMsg: 'Here will be the welcome message.',
        color: '#007bff',
        personality: 'personality',
        language: 'language',
        position: 'bottom-right',
        knowledgeBases: knowledgeBases, // to store knowledge base entries
        // ... any other fields ...
      });
      const handleWelcomeMsgChange = (event) => {
        const { checked } = event.target;
        setIsWelcomeMsgOn(checked);
    };
    const addKnowledgeBaseEntry = (newEntry) => {
        setKnowledgeBases((prevKnowledgeBases) => [...prevKnowledgeBases, newEntry]);
        setBotData((prev) => ({
            ...prev,
            ["knowledgeBases"]: knowledgeBases,
          }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBotData((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
      
    // handleNext and handlePrevious will update the currentStep state
    const handleNext = () => {
        handleSave();
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };
    const [customizations, setCustomizations] = useState({
        name: 'Name', // Default theme
        website: 'website', // Default theme
        theme: 'light', // Default theme
        color: '#007bff', // Default color
        position: 'bottom-right', // Default position
      });
    // Render the appropriate step content based on the current step
    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return <StepOneContent />;
            case 2:
                return <StepTwoContent />;
            case 3:
                return <StepThreeContent />;
            case 4:
                return <StepFourContent />;
            default:
                return <div>Unknown step</div>;
        }
    };
    
      const handleSave = async () => {
        // Perform validation or any other pre-submit logic here
        // ...
    
        try {
          const token = localStorage.getItem('userToken'); // Get token from local storage
          console.log('botData')
          console.log(botData)
          const response = await axios.post(API_URL+'/bots', botData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data); // Log or handle the response data+

          setBotData((prev) => ({
            ...prev,
            ["id"]: response.data.botId,
          }));
          // Redirect or perform actions after successful creation
          setCurrentStep(currentStep + 1);
          
        } catch (error) {
          console.error('Failed to create bot:', error);
          // Handle errors, such as displaying a message to the user
        }
      };
     
    const handleSubmit = async () => {
        // Perform validation or any other pre-submit logic here
        // ...
    
        try {
          const token = localStorage.getItem('userToken'); // Get token from local storage
          const response = await axios.post(API_URL+'/bots', botData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data); // Log or handle the response data
          // Redirect or perform actions after successful creation
          window.location.href = '/bot-library';
        } catch (error) {
          console.error('Failed to create bot:', error);
          // Handle errors, such as displaying a message to the user
        }
      };

    return (
        <>
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Bot Management</div>
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item"><a href="javascript:;"><FaPlus className="bx bx-home-alt" /></a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Create New</li>
                    </ol>
                </nav>
            </div>
            <div className="ms-auto">
                <div className="btn-group">
                    <button type="button" className="btn btn-light">Settings</button>
                    <button type="button" className="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a className="dropdown-item" href="javascript:;">Action</a>
                        <a className="dropdown-item" href="javascript:;">Another action</a>
                        <a className="dropdown-item" href="javascript:;">Something else here</a>
                        <div className="dropdown-divider"></div>	<a className="dropdown-item" href="javascript:;">Separated link</a>
                    </div>
                </div>
            </div>
        </div>
        

        <div className="container py-2">
      
          <div id="stepper2" className="bs-stepper">
            <div className="card">
              <div className="card-header">
                <div className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between" role="tablist">
                  {/* Step 1 */}
                  <div className={`step ${currentStep === 1 ? "active" : ""}`} onClick={() => setCurrentStep(1)}>
                    <div className="step-trigger" role="tab" id="stepper2trigger1" aria-controls="test-nl-1">
                        <div className="bs-stepper-circle"><i className='bx bx-user fs-4'></i></div>
                        <div className="">
                            <h5 className="mb-0 steper-title">Basic Info</h5>
                            <p className="mb-0 steper-sub-title">Bot basic Informations</p>
                        </div>
                    </div>                    
                  </div>
      
                  <div className="bs-stepper-line"></div>
      
                  {/* Step 2 */}
                  <div className={`step ${currentStep === 2 ? "active" : ""}`} onClick={() => setCurrentStep(2)}>
                    <div className="step-trigger" role="tab" id="stepper2trigger2" aria-controls="test-nl-2">
                        <div className="bs-stepper-circle"><i className='bx bx-file fs-4'></i></div>
                        <div className="">
                            <h5 className="mb-0 steper-title">Knowledge Base</h5>
                            <p className="mb-0 steper-sub-title">Add knowledge to the bot</p>
                        </div>
                    </div>
                  </div>
      
                  <div className="bs-stepper-line"></div>
      
                  {/* Step 3 */}
                  <div className={`step ${currentStep === 3 ? "active" : ""}`} onClick={() => setCurrentStep(3)}>
                    <div className="step-trigger" role="tab" id="stepper2trigger3" aria-controls="test-nl-3">
                        <div className="bs-stepper-circle"><i className='bx bxs-graduation fs-4'></i></div>
                        <div className="">
                            <h5 className="mb-0 steper-title">Theme & Display</h5>
                            <p className="mb-0 steper-sub-title">Customize the bot theme</p>
                        </div>
                    </div>
                  </div>
      
                  <div className="bs-stepper-line"></div>
      
                  {/* Step 4 */}
                  <div className={`step ${currentStep === 4 ? "active" : ""}`} onClick={() => setCurrentStep(4)}>
					  <div className="step-trigger" role="tab" id="stepper2trigger4" aria-controls="test-nl-4">
						<div className="bs-stepper-circle"><i className='bx bx-briefcase fs-4'></i></div>
						<div className="">
							<h5 className="mb-0 steper-title">Publish</h5>
							<p className="mb-0 steper-sub-title">Launch your bot live</p>
						</div>
					  </div>
                  </div>
                </div>
              </div>
      
              <div className="card-body">
                <div className="bs-stepper-content">
                     <div className="row">

                        {/* Content for Step 1 */}
                        {currentStep === 1 && <StepOneContent botData={botData} handleChange={handleChange} handleWelcomeMsgChange={handleWelcomeMsgChange} isWelcomeMsgOn={isWelcomeMsgOn} />}
                        {/* Content for Step 2 */}
                        {currentStep === 2 && <StepTwoContent botData={botData} handleChange={handleChange} addEntry={addKnowledgeBaseEntry} />}
                        {/* Content for Step 3 */}
                        {currentStep === 3 && <StepThreeContent botData={botData} handleChange={handleChange} addEntry={addKnowledgeBaseEntry}  customizations={customizations} />}
                        {/* Content for Step 4 */}
                        {currentStep === 4 && <StepFourContent />}
                        <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5">
                             <BotPreviewCreation name={customizations.name} theme={customizations.theme} color={customizations.color} position={customizations.position} botData={botData}/>

                        </div>
                     </div>
                     <div className="row justify-content-end p-4">

                                {/* Navigation buttons */}
                                {currentStep !== 1 && (
                                    <div className="col-6 col-lg-6 col-sm-12">
                                        <button className="btn btn-outline-light  btn-lg  px-4 w-100" onClick={() => setCurrentStep((prev) => prev - 1)}>
                                            Back
                                        </button>
                                    </div>
                                )}
                                {currentStep == 1 && (
                                    <div className="col-6 col-lg-6 col-sm-12">
                                        <button className="btn btn-outline-light  btn-lg  px-4 w-100" onClick={handleSave}>
                                            Save Draft
                                        </button>
                                    </div>
                                )}
                                {currentStep !== 4 ? (
                                    <div className="col-6 col-lg-6 col-sm-12">
                                        <button className="btn btn-light  btn-lg   px-4 w-100" onClick={() => {setCurrentStep((prev) => prev + 1);handleSave();}}>
                                            Next
                                        </button>
                                    </div>
                                ) : (
                                    <div className="col-6 col-lg-6 col-sm-12">
                                        <button className="btn btn-success btn-lg  px-4 w-100 " onClick={() => alert('Bot published!')}>
                                        Publish
                                        </button>
                                    </div>
                                )}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>

      );
      
};

// Define the content components for each step
const StepOneContent = ({ botData, handleChange, handleWelcomeMsgChange, isWelcomeMsgOn}) => {
    
 
    return (
        <>
            {/* Replace the divs below with your form fields */}
                <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5">
                    <h2>Set-up new bot </h2>
                    <label htmlFor="name" className='pt-3'>Name:</label>
                    <input type="text"  className="form-control form-control-lg" id="name" name="name"  value={botData.name} onChange={handleChange}  placeholder="Bot Name" required/>
                    <label htmlFor="url" className='pt-3'>Website URL:</label>
                    <input type="text" id="url" className="form-control form-control-lg" name="url" value={botData.url} onChange={handleChange} 
                    placeholder="https://example.com" />
                    <label htmlFor="url" className='pt-3'>Welcome Message:</label>

                    <div class="row">
                        <div class="col-lg-2 d-flex justify-content-center align-items-center">
                            <div class="form-control-lg form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={handleWelcomeMsgChange} checked={isWelcomeMsgOn} style={{width: '3em', height: '1.5em'}} alt='Let the bot start the conversation by enabling this option.'/>
                            </div>
                        </div>

                        <div class="col-lg-10">
                            <textarea type="text" id="welcomeMsg" class="form-control form-control-lg" name="welcomeMsg" value={botData.welcomeMsg} onChange={handleChange} placeholder="Hello, How can I assist you ..." disabled={!isWelcomeMsgOn}/>
                        </div>
                    </div>


                    
                    <label htmlFor="llm"  className='pt-3'>LLM:</label>
                    <select id="llm" name="llm" /* value={llm} onChange={handleLlmChange} */ className="form-control form-control-lg">
                        <option value="gpt4">GPT-4</option>
                        <option value="gpt3">GPT-3.5</option>
                        <option value="gpt3">Gemini</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
                
            {/* Include other form fields as needed */}
            {/* If this step has a submit button or needs to trigger something to go to the next step, include it here */}
            {/* <button onClick={handleSubmit}>Submit</button> */}
            </>
    );
};

const StepTwoContent = ({ botData, handleChange, addEntry }) => {
    // Step two content
    // ...
    return (
        <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5 fixed-col-6">
            {botData.knowledgeBases.length > 0 ? (
                botData.knowledgeBases.map((kb, index) => (
                <div key={kb.kb_id} className={`row`}>
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                        <span className="badge rounded-pill bg-white border">&nbsp;</span>
                    </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className={`card ${index % 2 === 0 ? 'border-primary shadow' : ''} radius-15`}>
                            <div className="card-body">
                                <div className="float-end small">{new Date(kb.created_at).toLocaleString()}</div>
                                <h4 className="card-title">{kb.title}</h4>
                                <p className="card-text">{kb.content}</p>
                                <p className="card-text">{kb.file_path}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                ))
            ) : (
                <p>No knowledge base entries found for bot {botData.id}x.</p>
            )}
            <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className="col">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                    <span className="badge rounded-pill bg-white border">&nbsp;</span>
                </h5>
                    <div className="row h-50">
                        <div className="col border-end">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col py-2">
                    <div className="card radius-15">
                        <div className="card-body">
                             <CreateKnowledgeBaseEntry botId={botData.id} addEntry={addEntry} />
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StepThreeContent = ({ botData, handleChange, addEntry, customizations }) => {
    // Step three content
    // ...
    return (
        <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5">
            <h2>Customize Theme</h2>
            <label className='pt-3'>Theme:</label>
            <select name="theme" value={customizations.theme} onChange={'handleCustomizationChange'} className="form-control form-control-lg">
                <option value="light">Light</option>
                <option value="dark">Dark</option>
            </select>
            <label className='pt-3'>Main Color:</label>
            <input type="color" name="color" value={botData.color} onChange={handleChange}  className="form-control form-control-lg"  />
            <label className='pt-3'>Position:</label>
            <select name="position" value={customizations.position} onChange={'handleCustomizationChange'} className="form-control form-control-lg">
                <option value="bottom-right">Bottom Right</option>
                <option value="bottom-left">Bottom Left</option>
                <option value="top-right">Top Right</option>
                <option value="top-left">Top Left</option>
            </select>
            
        </div>
        
    );
};

const StepFourContent = () => {
    const [bots, setBots] = useState([]); // State to store the list of bots
    const [selectedBot, setSelectedBot] = useState(null); // State to store the selected bot's ID
    const [embedCode, setEmbedCode] = useState(''); // State to store the generated embed code
  
    return (
        <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5">
             <h2>Publish it!</h2>
            <label htmlFor="url" className='pt-3'>Website URL (optional):</label>
            <input type="text" id="url" className="form-control form-control-lg" name="url" /* value={url} onChange={handleUrlChange} */ placeholder="https://example.com" />
            <label htmlFor="url" className='pt-3'>Copy and paste the code below into your website to integrate the bot.</label>

            <AceEditor
                mode="html"
                theme="github"
                name="embedCodeEditor"
                value={embedCode}
                readOnly={true}
                highlightActiveLine={false}
                showPrintMargin={false}
                showGutter={true}
                focus={false}
                setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
                }}
                style={{ width: '100%', height: '200px' }}
            />

        </div>
    );
};

export default CreateBot;
