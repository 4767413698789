import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Sidebar from './layout/Sidebar';
import TopBar from './layout/TopBar';
import Dashboard from './dashboard/Dashboard';
import BotManagement from './botManagement/BotManagement';
import CreateBot from './botManagement/CreateBot';
import BotLibrary from './botManagement/BotLibrary';
import CreateKnowledgeBaseEntry from './knowledgeBase/CreateKnowledgeBaseEntry';
import KnowledgeBaseManagement from './knowledgeBase/KnowledgeBaseManagement';
import KnowledgeBaseList from './knowledgeBase/KnowledgeBaseList';
import WebIntegration from './integration/WebIntegration';
import MobileIntegration from './integration/MobileIntegration';
import ApiAccess from './integration/ApiAccess';
import Login from './layout/Login';
import Signup from './layout/Signup';
import ChatDesign from './design/ChatDesign';
import Chat from './chats/Chat';
import SocialMediaIntegration from './integration/SocialMediaIntegration';
import './App.css';
import './assets/css/app.css';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

const AuthenticatedLayout = () => {
    return (
        <div className="wrapper ">
            <Sidebar />
            <TopBar />
            <div className="page-wrapper">
                <div className="page-content">
                    <Routes>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="bot-management" element={<BotManagement />} />
                        <Route path="create-bot" element={<CreateBot />} />
                        <Route path="bot-library" element={<BotLibrary />} />
                        <Route path="knowledge-base/:botId" element={<KnowledgeBaseManagement />} />
                        <Route path="create-knowledge-base" element={<CreateKnowledgeBaseEntry />} />
                        <Route path="integration/web" element={<WebIntegration />} />
                        <Route path="integration/mobile" element={<MobileIntegration />} />
                        <Route path="integration/api" element={<ApiAccess />} />
                        <Route path="integration/social" element={<SocialMediaIntegration />} />
                        <Route path="design/chat" element={<ChatDesign />} />
                        <Route path="knowledge-base" element={<KnowledgeBaseList />} />
                        <Route path="chats" element={<Chat />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/*" element={<ProtectedRoute><AuthenticatedLayout /></ProtectedRoute>} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
