import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logoIcon from '../assets/images/logo-icon.png';
import '../assets/css/Signup.css';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [country, setCountry] = useState('Morocco'); // Defaulting to Morocco as selected
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setError('You must agree to the terms and conditions.');
      return;
    }
    try {
      const response = await axios.post(API_URL+'/signup', {
        username,
        email,
        password,
        country,
      });
      localStorage.setItem('userToken', response.data.token); // Store the token
      login(); // Update the global auth state to reflect that the user is logged in
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to sign up');
      console.error(err);
    }
  };

  return (
    <div className="section-authentication-cover w-100">
      <div className="row g-0">
        <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
          <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0">
            <div className="card-body">
              <img src="assets/images/login-images/login-cover.svg" className="img-fluid auth-img-cover-login" width="650" alt=""/>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right bg-light align-items-center justify-content-center">
          <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
            <div className="card-body p-sm-5">
              <div className="mb-3 text-center">
                <img src={logoIcon} width="60" alt="logo"/>
              </div>
              <div className="text-center mb-4">
                <h3 className="logo-text">BOTGROUND</h3>
                <p className="mb-0">Please sign up to your account</p>
              </div>
              {error && <div className="alert bg-danger border-0 border-start border-5 border-white alert-dismissible fade show">
									<div className="text-white">{error}</div>
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
								</div>}
              <form className="form-body row g-3" onSubmit={handleSignup}>
                <div className="col-12">
                  <label htmlFor="inputUsername" className="form-label">Username</label>
                  <input type="text" className="form-control" id="inputUsername" placeholder="John" value={username} onChange={e => setUsername(e.target.value)} />
                </div>
                <div className="col-12">
                  <label htmlFor="inputEmailAddress" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="inputEmailAddress" placeholder="example@user.com" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="col-12">
                  <label htmlFor="inputChoosePassword" className="form-label">Password</label>
                  <input type="password" className="form-control" id="inputChoosePassword" value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="col-12">
                  <label htmlFor="inputSelectCountry" className="form-label">Country</label>
                  <select className="form-select" id="inputSelectCountry" value={country} onChange={e => setCountry(e.target.value)}>
                    <option value="Morocco">Morocco</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="America">America</option>
                    <option value="Dubai">Dubai</option>
                  </select>
                </div>
                <div className="col-12">
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)}/>
													<label className="form-check-label" for="flexSwitchCheckChecked">I read and agree to Terms & Conditions</label>
												</div>
											</div>
											<div className="col-12">
												<div className="d-grid">
													<button type="submit" className="btn btn-light">Sign up</button>
												</div>
											</div>
											<div className="col-12">
												<div className="text-center ">
													<p className="mb-0">Already have an account? <a href="/login">Sign in here</a></p>
												</div>
											</div>
                </form>
                </div>
                
                <div className="login-separater text-center mb-5"> <span>OR SIGN UP WITH EMAIL</span>
										<hr/>
									</div>
									<div className="list-inline contacts-social text-center">
										<a href="javascript:;" className="list-inline-item bg-light text-white border-0 rounded-3"><i className="bx bxl-facebook"></i></a>
										<a href="javascript:;" className="list-inline-item bg-light text-white border-0 rounded-3"><i className="bx bxl-twitter"></i></a>
										<a href="javascript:;" className="list-inline-item bg-light text-white border-0 rounded-3"><i className="bx bxl-google"></i></a>
										<a href="javascript:;" className="list-inline-item bg-light text-white border-0 rounded-3"><i className="bx bxl-linkedin"></i></a>
									</div>

            </div>
          </div>
        </div>
      </div>
  );
};

export default Signup;