import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logoIcon from '../assets/images/logo-icon.png';
import { 
  FaTachometerAlt, 
  FaRobot, 
  FaPlug, 
  FaGlobe , 
  FaCode, 
  FaComments, 
  FaPalette, 
  FaChartBar, 
  FaLifeRing, 
  FaCreditCard,
  FaSignOutAlt, 
  FaSearch, 
  FaBars,
  FaUserCircle,
  FaBook,
  FaArchive,
  FaGlobeAmericas,
  FaMobileAlt,
  FaChevronDown, 
  FaChevronRight ,
  FaHome ,
  FaPaintBrush, 
  FaDesktop, 
  FaShapes,
  FaClipboardList, // Add this line for Subscriptions
} from 'react-icons/fa';
import { IoIosFlash } from "react-icons/io";
import { FaDollarSign } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { MdOutlinePreview } from 'react-icons/md';

import '../assets/css/Sidebar.css';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const {  logout } = useAuth();

  const toggleSidebar = () => setIsOpen(!isOpen);
  const handleLogout = async () => {
    await logout();
    window.location.href = '/login';
  };

  const toggleDropdown = (key) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };
  return (
    <>
      <div className={`sidebar-wrapper ${isOpen ? 'open' : 'closed'}`} data-simplebar="true">
        <div className="sidebar-header">
          <div>
          <img src={logoIcon} alt="logo icon" className="logo-icon" />

          </div>
          <div>
            <h4 className="logo-text">BOTGROUND</h4>
          </div>
          <div className="toggle-icon ms-auto" onClick={toggleSidebar}><FaBars /></div>
        </div>
        <ul className="metismenu" id="menu">
          {/* Dashboard Link */}
          <li>
            <Link to="/dashboard">
              <FaTachometerAlt className="parent-icon" />
              <span className="menu-title">Dashboard</span>
            </Link>
            
          </li> 
          {/* Chats */}
          <li>
            <Link to="/chats">
              <FaComments className="parent-icon" />
              <span className="menu-title">Chats</span>
            </Link>
            
          </li> 
          {/* Dynamic Dropdown for Bot Creation & Management */}
          <li>
          <a className="has-arrow" onClick={() => toggleDropdown('botManagement')}>
            <div className="parent-icon">
              <FaRobot />
            </div>
            <div className="menu-title">Bots</div>
            <div className="arrow-icon">
              {dropdownOpen['botManagement'] ? <FaChevronDown /> : <FaChevronRight />}
            </div>
          </a>
            {dropdownOpen['botManagement'] && (
              <ul className={`dropdown-animate ${dropdownOpen['botManagement'] ? 'open' : ''}`}>

                <li>
                  <Link to="/create-bot" className="sidebar-link">
                    <IoIosFlash className="menu-icon" />
                    <span>Quick Start</span>
                  </Link>
                </li>
                <li>
                  <Link to="/bot-library" className="sidebar-link">
                    <FaArchive className="menu-icon" />
                    <span>Bot Library</span>
                  </Link>
                </li>
                <li>
                  <Link to="/knowledge-base" className="sidebar-link">
                    <FaBook className="menu-icon" />
                    <span>Knowledge Base</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
           {/* Integration */}
           <li>
            <a className="has-arrow" onClick={() => toggleDropdown('integrationSetup')}>
              <div className="parent-icon">
                <FaPlug /> {/* Assuming FaPlug is related to 'Integration Setup' */}
              </div>
              <div className="menu-title">Integration</div>
              <div className="arrow-icon">
                {dropdownOpen['integrationSetup'] ? <FaChevronDown /> : <FaChevronRight />}
              </div>
            </a>
            {dropdownOpen['integrationSetup'] && (
              <ul className={`dropdown-animate ${dropdownOpen['integrationSetup'] ? 'open' : ''}`}>

                <li>
                  <Link to="/integration/web" className="sidebar-link">
                    <FaGlobe  className="menu-icon" />
                    <span>Web</span>
                  </Link>
                </li>
                <li>
                  <Link to="/integration/mobile" className="sidebar-link">
                    <FaMobileAlt className="menu-icon" />
                    <span>Mobile App</span>
                  </Link>
                </li>
                <li>
                  <Link to="/integration/social" className="sidebar-link">
                    <IoShareSocial className="menu-icon" />
                    <span>Social Media</span>
                  </Link>
                </li>
                <li>
                  <Link to="/integration/api" className="sidebar-link">
                    <FaCode className="menu-icon" />
                    <span>API Access</span>
                  </Link>
                </li>
              </ul>
            )}
        
          </li>

          {/* Design */}
          <li>
                          
              <a className="has-arrow" onClick={() => toggleDropdown('designAppearance')}>
                <div className="parent-icon">
                  <FaPaintBrush /> {/* Icon representing design/editing */}
                </div>
                <div className="menu-title">Design</div>
                <div className="arrow-icon">
                  {dropdownOpen['designAppearance'] ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </a>
              {dropdownOpen['designAppearance'] && (
                <ul className={`dropdown-animate ${dropdownOpen['designAppearance'] ? 'open' : ''}`}>

                  <li>
                    <Link to="/design/chat" className="sidebar-link">
                      <FaDesktop className="menu-icon" />
                      <span>Chat Design</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/design/conversation-style" className="sidebar-link">
                      <FaShapes className="menu-icon" />
                      <span>Conversation Style</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/design/templates" className="sidebar-link">
                      <MdOutlinePreview className="menu-icon" />
                      <span>Preview & Templates</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/design/responsive" className="sidebar-link">
                      <FaMobileAlt className="menu-icon" />
                      <span>Responsive Design</span>
                    </Link>
                  </li>
                </ul>
              )}
        
          </li>
         {/* Subscriptions */}
         <li>
            <Link to="/subscriptions">
              <FaClipboardList className="parent-icon" />
              <span className="menu-title">Subscriptions</span>
            </Link>
            
          </li> 
         {/* Subscriptions */}
         <li>
            <Link to="/invoices">
              <FaDollarSign  className="parent-icon" />
              <span className="menu-title">Invoices</span>
            </Link>
            
          </li> 
          {/* Logout Button */}
          <li>
            <Link onClick={handleLogout}  className="sidebar-link">
              <FaSignOutAlt className="parent-icon" />
              <span  className="menu-title">Log Out</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
