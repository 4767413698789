import React, { useState } from 'react';
import axios from 'axios';

const WhatsAppIntegration = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSendCode = async () => {
    try {
      // Replace with your backend endpoint
      const response = await axios.post('/api/send-whatsapp-code', { phone: phoneNumber });
      console.log(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error sending code:', error);
    }
  };

  const handleVerifyCode = async () => {
    try {
      // Replace with your backend endpoint
      const response = await axios.post('/api/verify-whatsapp-code', { code: verificationCode });
      console.log(response.data);
      // Further actions after verification
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  };

  return (
    <div>
      <input
        type="tel"
        placeholder="Enter your phone number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <button onClick={handleSendCode}>Send Code</button>

      {modalOpen && (
        <div>
          {/* Modal content */}
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <button onClick={handleVerifyCode}>Grant Permission</button>
        </div>
      )}
    </div>
  );
};

export default WhatsAppIntegration;
