import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateKnowledgeBaseEntry = ({ botId, addEntry  }) => {
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(botId);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(API_URL+'/bots', {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}`,'Content-Type': 'application/json' },
        });
        setBots(response.data);
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchBots();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    if(!selectedBot){
      setSelectedBot(botId)
    }
    const formData = new FormData();
    formData.append('bot_id', selectedBot);
    formData.append('title', title);
    formData.append('content', content);
    if (file) {
      formData.append('file', file);
    }
    try {
      // await axios.post(API_URL+'/knowledge-base', {
      //   bot_id: selectedBot, // Changed from botId to bot_id to match server expectation
      //   title,
      //   content,
      //   file,
      // }, {
      //   headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
      // });
      const response = await axios.post(API_URL+'/knowledge-base', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
      const newEntry = {
        kb_id: response.data.entry.kb_id,
        bot_id: selectedBot,
        title: title,
        content: content,
        file_path: response.data.entry.file_path,
        // You might also want to include any other data you need, like timestamps or IDs
      };
      console.log(response.data.entry.kb_id);
      console.log('response.data.entry.file_path');
      console.log(response.data.entry.file_path);
      console.log(response.data.file_path);
      console.log(response.data.file_path);
      console.log(response.data.file_path);

      addEntry(newEntry);

      // await new Promise(resolve => setTimeout(resolve, 2000));
      // Handle successful creation
      setTitle('');
      setContent('');
      setFile(null);  
    } catch (error) {
      console.error('Error creating knowledge base entry:', error);
    }finally {
      setIsLoading(false); // End loading
    }
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>

        <h2>New Knowledge Base Entry  </h2>
        <div>
          {/* <select className="form-select form-control-lg mb-3" value={selectedBot} onChange={(e) => setSelectedBot(e.target.value)}>
              {bots.map((bot) => (
                <option key={bot.bot_id} value={bot.bot_id}>{bot.name}</option>
              ))}
            </select> */}


        </div>
        <div>
          <input className="form-control form-control-lg mb-3" type="text" placeholder="Name" aria-label=".form-control-lg example" value={title} onChange={(e) => setTitle(e.target.value)} required ></input>
        </div>
        <div>
          <textarea className="form-control form-control-lg mb-3" id="inputAddress" placeholder="Content" rows="3" alue={content} onChange={(e) => setContent(e.target.value)} required ></textarea>
        </div>
          <div class="input-group mb-3">
            <input type="file" className="form-control form-control-lg " id="inputGroupFile02" onChange={handleFileChange} />
            <label className="input-group-text" for="inputGroupFile02">Upload</label>
          </div>
        <div>
        {isLoading ?         
        <button className=" form-control btn btn-dark btn-lg px-5" type="button" disabled> 
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span className="visually-hidden">Loading...</span>
        </button>
        : <button type="submit" className="form-control  btn btn-light btn-lg px-5">Create</button>}
      {isLoading && <p>Loading...</p>}

        </div>
      </form>
    </>
  );
};

export default CreateKnowledgeBaseEntry;
