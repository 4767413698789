import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {FaArchive, FaBook} from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { FaPlus, FaSearch, FaSort, FaTh, FaList } from 'react-icons/fa';

const BotLibrary = () => {
  const [bots, setBots] = useState([]);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'table'
  const API_URL = process.env.REACT_APP_API_URL;

  console.log(localStorage.getItem('userToken'));
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => 'Web Assistant',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => <div class="badge rounded-pill bg-success text-white w-100">{row.status}</div>,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        
                <div className="list-inline mt-3 mb-3"> 
                    <Link to={`/knowledge-base/${row.bot_id}`} className="list-inline-item bg-light text-white border-0"><i className="bx bx-book"></i></Link>
                    <a href="/knowledge-base/${row.bot_id}" className="list-inline-item bg-light text-white border-0"><i className="bx bx-pencil"></i></a>
                  <a href="/knowledge-base/${row.bot_id}" className="list-inline-item bg-light text-white border-0"><i className="bx bx-show-alt"></i></a>
              </div>

      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        borderStyle: 'solid',
        borderWidth: '0.1px',
        borderColor: 'rgba(255, 255, 255/ 15%)',
        backgroundColor: ' rgba(0, 0, 0, 0.6)', // Body row background color to match the header
      },
    },
    headCells: {
      style: {
        color: '#fff', // Bootstrap 'table-dark' header font color
        '&:not(:last-of-type)': {
          borderStyle: 'solid',
          borderWidth: '0.1px',
          borderColor: 'rgba(255, 255, 255/ 15%)', // The color for the header cell borders
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderStyle: 'solid',
          borderWidth: '0.1px',
          borderColor: 'rgba(255, 255, 255/ 15%)', // The color for the cell borders
        },
      },
    },
    rows: {
      style: {
        backgroundColor: ' rgba(0, 0, 0, 0.4)', // Body row background color to match the header
        
        minHeight: '48px', // Minimum height for rows
        '&:not(:last-of-type)': {
          borderStyle: 'solid',
          borderWidth: '0.1px',
          borderColor: 'rgba(255, 255, 255/ 15%)', // The color for the row borders
          color: '#fff'
        },
      },
    },
    table: {
      style: {
        color: '#343a40', // The table text color (if needed)
      },
    },
  };
  
  
  
  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(API_URL+`/bots`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setBots(response.data);
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchBots();
  }, []);
  const toggleViewMode = () => {
    setViewMode(viewMode === 'grid' ? 'table' : 'grid');
  };
  const generateToken = async (botId) => {
    try {
      const response = await axios.post(API_URL+`/generate-bot-token`, { botId }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
      // Update the bots state to include the new token
      setBots(bots.map(bot => bot.bot_id === botId ? { ...bot, bot_token: response.data.botToken } : bot));
      alert(`Token generated: ${response.data.botToken}`); // Display the token to the user
    } catch (error) {
      console.error('Error generating token:', error);
    }
  };
  const copyToken = (token) => {
    navigator.clipboard.writeText(token).then(() => {
      alert('Token copied to clipboard!');
    }, (err) => {
      console.error('Could not copy token: ', err);
    });
  };
  return (
    <>
  <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div class="breadcrumb-title pe-3">Bot Management</div>
      <div class="ps-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><a href="javascript:;"><FaArchive className="bx bx-home-alt" /></a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Bot Library</li>
          </ol>
        </nav>
      </div>
    </div>
      {/* Second topbar... */}

      {/* Added HTML snippet transformed into JSX */}
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-lg-3 col-xl-2">
              <Link to={`/create-bot`} className="btn btn-light mb-3 mb-lg-0">
                
                  <FaPlus className="bx bxs-plus-square " />
                  <span className=''>New Bot</span>
              </Link>

            </div>
            <div className="col-lg-9 col-xl-10">
              <form className="float-lg-end">
                <div className="row row-cols-lg-auto g-2">
                  <div className="col-12">
                    <div className="position-relative">
                      <input type="text" className="form-control ps-5" placeholder="Search Bot..." />
                      <span className="position-absolute top-50 product-show translate-middle-y">
                        <FaSearch />
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                      <button type="button" className="btn btn-light">Sort By</button>
                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-light dropdown-toggle dropdown-toggle-nocaret px-1">
                          <FaSort />
                        </button>
                        {/* Dropdown items */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                      <button type="button" className="btn btn-light" onClick={toggleViewMode}>
                        {viewMode === 'grid' ? <FaTh /> : <FaList />}
                      </button>

                    
                      {/* Dropdown items */}
                    </div>
                  </div>
                  {/* Rest of your form elements */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {viewMode === 'grid' ? (
                      // Grid view (existing code)
                      <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
                            {bots.map(bot => (
                              <div className="col">
                                <div className="card radius-15 " >
                                  <div className="card-body text-center">
                                    <div className="p-4 border radius-15">
                                      <img src="../assets/images/bwclogo.jpg" width="110" height="110" className="rounded-circle shadow" alt="" />
                                      <h5 className="mb-0 mt-5">{bot.name}</h5>
                                      <p className="mb-3">Web Assistant</p>
                                      <div className="list-inline contacts-social mt-3 mb-3"> 
                                        <a href="javascript:;" className="list-inline-item bg-light text-white border-0"><i className="bx bx-pencil"></i></a>
                                        <a href="javascript:;" className="list-inline-item bg-light text-white border-0"><i className="bx bx-show-alt"></i></a>
                                        {bot.bot_token &&<a  onClick={() => copyToken(bot.bot_token)} className="list-inline-item bg-light text-white border-0">c</a>}
                                        <a href="javascript:;" className="list-inline-item bg-light text-white border-0"><i className="bx bx-show-alt"></i></a>
                                      </div>
                                      <div className="d-grid"> 
                                      <Link to={`/knowledge-base/${bot.bot_id}`} className="btn btn-light radius-15">Manage Knowledge</Link>
                                      {!bot.bot_token &&
                                        <Link onClick={() => generateToken(bot.bot_id)} className="btn btn-primary radius-15">Generate Token</Link>
                                      }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                            ) : (
                              
                        <div className="row ">
                            <div class="card">
                              <div class="card-body">
                                <div class="table-responsive">
                                
                              <DataTable
                                  columns={columns}
                                  data={bots}
                                  customStyles={customStyles}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                            )}

      {/* end of second topbar.. */}
   
    </>
    
  );
};

export default BotLibrary;