import React, { useState, useEffect } from 'react';
import { FaRobot, FaPaperPlane } from 'react-icons/fa';
import PropTypes from 'prop-types';

const BotPreviewCreation = ({ name,theme, color, position,botData }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  // Function to send a message
  const sendMessage = () => {
    if (inputValue.trim() === '') return;

    const newMessage = { sender: 'user', text: inputValue };
    setMessages([...messages, newMessage]);
    setInputValue('');

    setIsBotTyping(true);
    // Simulate bot response
    setTimeout(() => {
      const botResponse = { sender: 'bot', text: 'This is a sample response.' };
      setMessages((currentMessages) => [...currentMessages, botResponse]);
      setIsBotTyping(false);
    }, 1000); // Simulate bot typing delay
  };


  // Set the position of the bot icon based on the 'position' prop
  const botStyle = {
    position: 'fixed',
    [position.includes('bottom') ? 'bottom' : 'top']: '20px',
    [position.includes('right') ? 'right' : 'left']: '20px',
  };

  // Set the icon color based on the 'color' prop
  const botIconStyle = {
    color: botData.color,
  };

  useEffect(() => {
    setMessages((currentMessages) => [{ sender: 'bot', text: botData.welcomeMsg }]);
  },[botData.welcomeMsg]);
  // Apply the theme class based on the 'theme' prop
//   const chatClass = `chat-preview ${theme} ${isChatOpen ? 'open' : 'closed'}`;
  const chatClass = `chat-preview ${theme} open`;
  const conversationClass = `chat-conversation ${theme} open`;
  return (

            <div className={chatClass}>
                <div className={conversationClass}>
                    <div className="chat-header" onClick={toggleChat} style={{ backgroundColor: botData.color }}>
                    {botData.name ? botData.name : "Bot Name"}
                    </div>
                    <div className="chat-messages">
                        {messages.map((message, index) => (
                            <div
                            key={index}
                            className={`message ${message.sender}-message`}
                            style={message.sender === 'user' ? { backgroundColor: botData.color } : {}}
                            >
                            {message.text}
                            </div>
                        ))}
                            {isBotTyping && (
                            <div className="typing-indicator">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                            )}
                    </div>
                    <div className="chat-input">
                    <textarea
                      type="text"
                      placeholder="Type a message..."
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                    />
                    <button onClick={sendMessage} style={{ backgroundColor: color }}><FaPaperPlane /></button>
                    </div>
                </div>
            </div>

  );
};

// Define prop types for validation
BotPreviewCreation.propTypes = {
  theme: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

// Provide default props in case they are not passed
BotPreviewCreation.defaultProps = {
  theme: 'light',
  color: '#007bff',
  position: 'bottom-right',
};

export default BotPreviewCreation;
