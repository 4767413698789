import React from 'react';

const BotIcon = ({ onClick, style }) => {
    return (
      <svg
        onClick={onClick}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60.7 60.41"
      >
        <defs>
          <style>
            {`.cls-1 { fill: `+style['color']+`; stroke-width: 0px; }`}
          </style>  
        </defs>
        <path
          className="cls-1"
          d="m59.91,4.79c-.22-.47-.49-.92-.79-1.34-1.52-2.09-3.98-3.45-6.76-3.45H8.34C3.73,0,0,3.73,0,8.34v29.48c0,6.48,5.25,11.73,11.73,11.73,0,0,19.23-.02,30.11-.01,4.45,0,8.75,1.66,12.04,4.66,3.24,2.95,6.83,6.21,6.83,6.21V8.34c0-1.27-.28-2.47-.79-3.55Zm-41.15,27.47c0,2.27-1.84,4.12-4.12,4.12s-4.12-1.84-4.12-4.12v-15.51c0-1.29,1.05-2.34,2.34-2.34h3.51c1.32,0,2.38,1.07,2.38,2.38v15.47Zm28.55-23.08l-1.91,1.52c-.17.13-.07.4.14.4h.88c.21-.02.31.26.14.39l-2.98,2.23c-.17.13-.08.4.13.4h.64c.19,0,.29.24.16.38l-10.36,10.99s-.06.1-.06.15v6.65c0,2.27-1.84,4.12-4.12,4.12-1.14,0-2.17-.46-2.91-1.21-.74-.74-1.21-1.77-1.21-2.91v-15.6c0-1.24,1.01-2.25,2.25-2.25h3.68c1.27,0,2.3,1.03,2.3,2.3,0,.13.11.22.22.22.03,0,.07,0,.1-.03l7.9-4.25c.2-.11.12-.42-.11-.42h-.39c-.23,0-.31-.3-.11-.41l5.35-3.05c.24-.14.46.2.25.37Z"
        />
      </svg>
    );
  };
  
  export default BotIcon;
  