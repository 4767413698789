import React from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import Split from "./Split";
import '../assets/css/CallToAction.css';

const CallToAction = ({ img, theme, subBG }) => {
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const subscribeToNewsletter = async (email) => {
    try {
      const response = await axios.post("/api/subscribe", { email });
      return response.data;
    } catch (error) {
      console.error("Subscription failed: ", error);
      throw new Error("Subscription failed");
    }
  };

  return (
    <section
      className={`call-action section-padding ${subBG ? "sub-bg" : ""} bg-img`}
      style={{ backgroundImage: `url(${img ? img : "/img/pattern.png"})` }}
    >
      <div className="container offset-lg-1">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <div className="content sm-mb30">
              <Split>
                <h6 className="wow words chars splitting" data-splitting>
                  Early Access
                </h6>
                <h2
                  className="wow words custom-font chars splitting"
                  data-splitting
                >
                  limited <b>waitlist</b>.
                </h2>
              </Split>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-lg-1 ml-10">
            <div className="subscribe mb-50">
              <Formik
                initialValues={{ subscribe: "" }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await subscribeToNewsletter(values.subscribe);
                    alert("Subscription successful!");
                    resetForm();
                  } catch (error) {
                    alert("Subscription failed. Please try again.");
                  }
                  setSubmitting(false);
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="form-group custom-font">
                      <Field
                        validate={validateEmail}
                        type="email"
                        name="subscribe"
                        placeholder="Your Email"
                      />
                      {errors.subscribe && touched.subscribe && (
                        <div style={{marginTop:'15px',backgroundColor:'transparent'}}>{errors.subscribe}</div>
                      )}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="cursor-pointer"
                      >
                        Join
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
