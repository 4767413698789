import React from 'react';

const CustomizationForm = ({ bots, selectedBot, customizations, handleBotSelectionChange, handleCustomizationChange, handleSaveCustomizations }) => {
  return (
    <div className="col-12 col-lg-12 h-100 d-flex flex-column justify-content-center p-5 " style={{color:'white !important'}}>
      <figure className="text-left">
        <div>
          <h3>Select Bot:</h3>
          <select value={selectedBot} onChange={handleBotSelectionChange} className='form-control form-control-lg'>
            {bots.map((bot) => (
              <option key={bot.bot_id} value={bot.bot_id}>{bot.name}</option>
            ))}
          </select>
        </div>
        <br/>
        <h3>Customization Options</h3>
        <div>
          <label>Theme:</label>
          <select name="theme" value={customizations.theme} onChange={handleCustomizationChange} className='form-control form-control-lg'>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>
        <div>
          <label>Color:</label>
          <input type="color" name="color" value={customizations.color} onChange={handleCustomizationChange} className='form-control form-control-lg' />
        </div>
        <div>
          <label>Icon Color:</label>
          <input type="color" name="iconcolor" value={customizations.iconcolor} onChange={handleCustomizationChange} className='form-control form-control-lg' />
        </div>
        <div>
          <label>Position:</label>
          <select name="position" value={customizations.position} onChange={handleCustomizationChange} className='form-control form-control-lg'>
            <option value="bottom-right">Bottom Right</option>
            <option value="bottom-left">Bottom Left</option>
            <option value="top-right">Top Right</option>
            <option value="top-left">Top Left</option>
          </select>
        </div>
        <button onClick={() => handleSaveCustomizations('basic')} className="save-customizations-btn btn btn-light btn-lg mt-5">
          Save Customizations
        </button>
      </figure>
    </div>
  );
};

export default CustomizationForm;
