import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';

const CodeSnippetGenerator = ({handleBotSelectionChange}) => {
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [embedCode, setEmbedCode] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(API_URL+`/bots`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setBots(response.data.filter(bot => bot.status === 'active'));
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchBots();
  }, []);

  useEffect(() => {
    if (selectedBot) {
      const bot = bots.find(bot => bot.bot_id.toString() === selectedBot);
      console.log(bot,"11111111111111111111111111111111111111111111");
      console.log(bot);

      if (bot && bot.bot_token) {
        // Point to where your local server serves the botPreview.bundle.js
        const devURL = 'http://localhost:3000/static/js/botPreview.bundle.js'; // Adjust the port and path as necessary
        const prodURL = 'http://app.botground.xyz/static/js/botPreview.bundle.js'; // Adjust the port and path as necessary
        const code = `<div id="bot-preview-container"></div>
  <script src="${prodURL}"></script>
  <script>
      window.onload = function() {
          initBotPreview('bot-preview-container', '${bot.bot_token}', 'dark', '#007bff', 'bottom-right');
      };
  </script>`;
        setEmbedCode(code);
      } else {
        setEmbedCode('The bot doesn\'t have a token or URL');
      }
    }
  }, [selectedBot, bots]);
  

  const handleBotSelection = (e) => {
    setSelectedBot(e.target.value);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode)
      .then(() => alert('Copied to clipboard!'))
      .catch(err => alert('Failed to copy text: ', err));
  };

  return (
    <div>
      <h3>Code Snippet Generator</h3>
      <p>Select your bot to generate the embed code:</p>
      <select
          onChange={(e) => {
            handleBotSelection(e);
            handleBotSelectionChange(e);
          }}
          value={selectedBot || ''}
          className='form-control form-control-lg'
        >
        <option value="">Select a Bot</option>
        {bots.map((bot) => (
          <option key={bot.bot_id} value={bot.bot_id}>
            {bot.name}
          </option>
        ))}
      </select>

      <h3>Embed Code:</h3>
      <AceEditor
        mode="html"
        theme="github"
        name="embedCodeEditor"
        value={embedCode}
        readOnly={true}
        highlightActiveLine={false}
        showPrintMargin={false}
        showGutter={true}
        focus={false}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        style={{ width: '100%', height: '200px' }}
      />
      <button onClick={copyToClipboard} style={{ margin: '10px 0', padding: '10px 20px' }}>Copy to Clipboard</button>
      <p>Copy and paste the code above into your website to integrate the bot.</p>
    </div>
  );
};

export default CodeSnippetGenerator;
