import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const TopBar = () => {
  const { currentUser, logout } = useAuth();
console.log(currentUser);
  return (
    <header>
      <div className="topbar d-flex align-items-center">
        <nav className="navbar navbar-expand gap-3">
          <div className="mobile-toggle-menu"><i className='bx bx-menu'></i></div>
         
          <div className="top-menu ms-auto">
            <ul className="navbar-nav align-items-center gap-1">
              {/* Navigation items */}
            </ul>
          </div>
          <div className="user-box dropdown px-3">
            <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="assets/images/avatars/avatar-2.png" className="user-img"  />
              <div className="user-info">
                <p className="user-name mb-0"></p>
                <p className="designation mb-0">{currentUser ? currentUser.user.username : 'Guest'}</p>
              </div>
            </a>
            {/* Dropdown menu */}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default TopBar;
