import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyUser = async () => {
            const token = localStorage.getItem('userToken');
            if (token) {
                try {
                    // Verify token and get user data. Adjust URL as necessary.
                    const response = await axios.get(API_URL+'/verify-token', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setCurrentUser(response.data); // Set user data on successful token verification
                } catch (error) {
                    console.error('Token verification failed:', error);
                }
            }
            setLoading(false);
        };

        verifyUser();
    }, []);

    const login = async ({ username, password }) => {
        try {
            console.log("toxxken");
            const response = await axios.post(API_URL+'/login', { username, password });
            const { token, user } = response.data;
            localStorage.setItem('userToken', token);
            console.log(token);
            console.log("toxxken");
            setCurrentUser(user); // Set user data on successful login
        } catch (error) {
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('userToken');
        setCurrentUser(null);
    };

    const isAuthenticated = () => !!currentUser;

    const value = {
        currentUser,
        login,
        logout,
        isAuthenticated,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
