// WebIntegration.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CodeSnippetGenerator from './CodeSnippetGenerator';
import BotPreview from './BotPreview';
import { FaPlug } from 'react-icons/fa';

const WebIntegration = () => {
  const [bots, setBots] = useState([]);
  const [bot, setBot] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;
  const [elementStyles, setElementStyles] = useState({});

  const [selectedBot, setSelectedBot] = useState('');
  const [customizations, setCustomizations] = useState({
    theme: 'light', // Default theme
    color: '#007bff', // Default color
    position: 'bottom-right', // Default position
  });
  const handleBotSelectionChange = (e) => {
    const botId = e.target.value;
    setSelectedBot(botId);
    const selected = bots.find((b) => b.bot_id.toString() === botId);
    console.log(customizations.iconcolor);
    console.log('customizations.iconcolor');
    setBots(prevBots => 
      prevBots.map(b => 
        b.bot_id === bot.bot_id 
          ? { ...b, elementStyles: elementStyles, iconStyle: {'color':customizations.iconcolor} } 
          : b
      )
    );
    let tttt = bots.find((b) => b.bot_id.toString() === botId);
    console.log(tttt);
    
    console.log('handleBotSelectionChange');
    if (selected) {
      setBot(selected);
      setElementStyles(selected.elementStyles);
      setCustomizations({
        theme: selected.theme || 'light',
        color: selected.color || '#FFA500',
        position: selected.position || 'bottom-right',
        iconcolor: selected.iconcolor || '#FFA500',
      });
    }
  };
  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(API_URL + '/bots', {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
        });
        console.log(response.data,"xxxxxxxxx???????????????????????????????????????????????");
        setBots(response.data);
        if (response.data.length > 0) {
          setSelectedBot(response.data[0].bot_id);
          setBot(response.data[0]);
          const elementStylesHash = response.data[0]['elementStyles'].reduce((acc, item) => (item.name === "bot-message" ? { [item.name]: item.template_data } : acc), {});
          setElementStyles(elementStylesHash)
          setCustomizations(prev => ({
            ...prev,
            theme: response.data[0].theme || prev.theme,
            color: response.data[0].color || prev.color,
            position: response.data[0].position || prev.position,
            iconcolor: response.data[0].iconcolor || prev.iconcolor,
          }));
          console.log(elementStyles,"111wqqqqqqxxxxxxxxx???????????????????????????????????????????????");
          console.log(elementStyles,"22222wqqqqqqxxxxxxxxx???????????????????????????????????????????????");

        }
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };
    fetchBots();
  }, [selectedBot]);
    console.log(bot,"???????????????????????????????????????????????");
  return (
    <>
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">Integration Setup</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item"><a href="javascript:;"><FaPlug className="bx bx-home-alt" /></a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Web Integration </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />
    <div className="card h-100">
        <div className="card-body">
          <div className='row'>
            <div className='col-md-6 d-flex flex-column justify-content-center p-5 text-center'>
                <figure className="">
                  <blockquote className="blockquote">
                    <h3>Integrate your bot into your website to engage visitors, provide instant support, and gather insights.</h3>
                    <ul className="list-style-none card p-4 text-white rounded">
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>01</h2> </li>
                      <li>Locate the HTML file of the page where you want the bot to appear.</li>
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>02</h2> </li>
                      <li>Place the bot's embed code snippet within the body of your webpage.</li>
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>03</h2> </li>
                      <li>Customize the appearance of your bot using the options below and preview the changes in real-time.</li>
                    </ul>
                  </blockquote>
                  <figcaption className="blockquote-footer"><cite title="Support">Support</cite>
                  </figcaption>
                </figure>
            </div>
            <div className='col-md-6 d-flex flex-column justify-content-center p-5 text-center'>
                  {selectedBot && (
                  <>
                    <BotPreview bot={bot} elementStyles={elementStyles} iconStyle={{'color':customizations.iconcolor}}  theme={customizations.theme} color={customizations.color} position={customizations.position} />
                    <CodeSnippetGenerator botId={selectedBot} customizations={customizations} handleBotSelectionChange={handleBotSelectionChange}/>
                  </>
                )}
              </div>
        </div>
      </div>
    </div>


    <div className='row'>
      <div className='col-md-12'>
        <div className="card">
          <div className="card-body">

            <div className="row">
               
                
            </div>
          </div>
        </div>
      </div>
    </div>

 
    </>
    );
  };
  
  export default WebIntegration;
