import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BotIcon from './BotIcon'; // Adjust the import path as necessary
import { FaRobot, FaPaperPlane } from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../assets/css/BotPreview.css'; // Ensure this path is correct for CSS styles

const BotPreview = ({ bot, theme, color, iconStyle = {}, position, elementStyles = {} }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [conversationId, setConversationId] = useState();
  const [currentBot, setCurrentBot] = useState({ name: 'BotGround' });
  const [dynamicElementStyles, setDynamicElementStyles] = useState(elementStyles);
  const [dynamicIconStyle, setDynamicIconStyle] = useState(iconStyle);
  const API_URL = process.env.REACT_APP_API_URL;

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  let headers = '';
  let url = '';
  const userToken = localStorage.getItem('userToken');
  if (userToken) {
    headers = { Authorization: `Bearer ${userToken}` };
    url = API_URL + '/messages';
  } else {
    const botToken = bot.bot_id; // Adjust how you get this token as needed
    headers = { Authorization: `Bearer ${botToken}` };
    url = API_URL + '/messages-by-bot'; // Different endpoint for bot
  }

  useEffect(() => {
    const fetchBot = async () => {
      if (!userToken) {
          try {
            const response = await axios.get(API_URL + `/bots-by-bot`, {
            headers: headers,
          });

          setCurrentBot(response.data[0]);
          console.log(response.data[0].iconcolor)
          console.log(response.data[0].iconColor)
          console.log("response.data[0]")
          setDynamicElementStyles(response.data[0]?.elementStyles || {});
          setDynamicIconStyle({
            fill: response.data[0]?.iconcolor,
            color: response.data[0]?.iconcolor,
          });
          console.log(dynamicIconStyle)
          console.log(response.data[0].iconcolor)
          console.log("rdynamicIconStyle")
            console.log("elementStyles", dynamicElementStyles, "iconStyle", dynamicIconStyle);
          } catch (error) {
            console.log("headers", headers);
            console.error('Error fetching bots:', error);
          }
      }
    };

    fetchBot();
  }, []);
  useEffect(() => {
   // Check if elementStyles is not an empty object and has changed
   if (Object.keys(elementStyles).length !== 0 ) {
    setDynamicElementStyles(elementStyles);
    }
    // Check if iconStyle is not an empty object and has changed
    if (Object.keys(iconStyle).length !== 0 ) {
      setDynamicIconStyle(iconStyle);
    }
  }, [elementStyles,iconStyle]);

  console.log("ressssponse", bot);
  console.log("response");

  const sendMessage = async () => {
    if (inputValue.trim() === '') return;
    let tempConvId = '';
    try {
      const newMessage = { sender: 'user', content: inputValue, created_at: 'now' };
      setMessages([...messages, newMessage]);
      setInputValue('');

      setIsBotTyping(true);
      // const token = localStorage.getItem('userToken');
      const messageData = { conversation_id: conversationId, sender: "user", content: inputValue, receiver: 'bot', bot_id: bot.bot_id };
      const response = await axios.post(url, messageData, { headers });

      const { conversation, message, botMessage } = response.data;
      setConversationId(conversation.id);
      tempConvId = conversation.id;

      setMessages((currentMessages) => [...currentMessages, { 'sender': 'bot', 'content': botMessage, 'created_at': 'now' }]);
      setIsBotTyping(false);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const botStyle = {
    position: 'fixed',
    [position.includes('bottom') ? 'bottom' : 'top']: '20px',
    [position.includes('right') ? 'right' : 'left']: '20px',
  };

  const chatClass = `chat-preview ${theme} ${isChatOpen ? 'open' : 'closed'}`;
  const conversationClass = `chat-conversation ${theme}`;
  console.log("xxelementStyles", dynamicElementStyles, "iconStyle", dynamicIconStyle);

  return (
    <div className={`bot-preview ${theme} ${position}`} style={{ color }}>
      <div className="bot-preview-container" style={botStyle}>
        <div className={chatClass}>
          {isChatOpen && (
            <div className={conversationClass} style={elementStyles['conversation-background'] || dynamicElementStyles['conversation-background'] || {}}>
              <div className="chat-header" onClick={toggleChat} style={dynamicElementStyles['chat-header'] || { backgroundColor: color }}>
              {bot.name ? bot.name : (currentBot.name ? currentBot.name : "Bot Name")}

              </div>
              <div className="chat-messages">
                {messages.map((message, index) => (
                  message.sender === 'user' ? (
                    <div
                      key={index}
                      className={`message ${message.sender}-message`}
                      style={dynamicElementStyles['user-message'] || { backgroundColor: color }}
                    >
                      {message.content}
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={`message ${message.sender}-message`}
                      style={dynamicElementStyles['bot-message'] || {}}
                    >
                      {message.content}
                    </div>
                  )
                ))}
                {isBotTyping && (
                  <div className="typing-indicator">
                    <div className="dot"></div>
                  </div>
                )}
              </div>
              <div className="chat-input">
                <textarea
                  type="text"
                  placeholder="Type a message..."
                  value={inputValue}
                  style={dynamicElementStyles['text-input'] || {}}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button onClick={sendMessage} style={dynamicElementStyles['send-btn'] || { backgroundColor: color }}><FaPaperPlane />{}</button>
              </div>
            </div>
          )}
        </div>

        <BotIcon className="bot-icon" onClick={toggleChat} style={{
          color: `${dynamicIconStyle['color'] ? dynamicIconStyle['color'] : "#fff"} !important`,
          fill: `${dynamicIconStyle['color'] ? dynamicIconStyle['color'] : "#fff"} !important`,
          width: '50px',
          height: '50px',
          float: 'right',
          marginTop: '20px'
        }}
        />
      </div>
    </div>
  );
};

// Define prop types for validation
BotPreview.propTypes = {
  theme: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

// Provide default props in case they are not passed
BotPreview.defaultProps = {
  theme: 'light',
  color: '#007bff',
  position: 'bottom-right',
};

export default BotPreview;
