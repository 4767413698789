// Import necessary libraries
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Register the chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  Filler
);
// Define the Sidebar component
const Dashboard = () => {
	const API_URL = process.env.REACT_APP_API_URL;

	const [dashdata, setDashdata] = useState({
		botCount: 0,
		conversationCount: 0,
		messageCount: 0
	  });
	
	  const lineChartData = {
		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
		datasets: [
		  {
			label: 'New Visitor',
			data: [3, 3, 8, 5, 7, 4, 6, 4, 6, 3],
			backgroundColor: [
				'rgba(255, 255, 255, 1)' // Fully opaque white
			],
			fill: {
				target: 'origin',
				above: 'rgba(255, 255, 255, 1)',   // Fully opaque white above the origin
				below: 'rgba(255, 255, 255, 1)'   // Fully opaque white below the origin
			},
			tension: 0.4,
			borderColor: [
				'rgba(255, 255, 255, 1)' // Fully opaque white
			],
			pointRadius: 0,
			borderWidth: 3
		  },
		  {
			label: 'Old Visitor',
			data: [7, 5, 14, 7, 12, 6, 10, 6, 11, 5],
			backgroundColor: [
				'transparent'
			],
			fill: {
				target: 'origin',
				above: 'rgba(255, 255, 255, 0.25)',   // Lighter white (25% opacity) above the origin
				below: 'rgba(255, 255, 255, 0.25)'    // Lighter white (25% opacity) below the origin
			},
			tension: 0.4,
			borderColor: [
				'transparent'
			],
			pointRadius: 0,
			borderWidth: 1
		  }
		]
	};
	
	const lineChartOptions = {
		maintainAspectRatio: false,
		plugins: {
		  legend: {
			display: false  // Ensuring the legend is not displayed
		  }
		},
		scales: {
		  x: {
			stacked: false,
			beginAtZero: true,
			ticks: {
			  color: "rgba(255, 255, 255, 0.75)", // White with 75% opacity
			},
			grid: {
			  display: true,
			  color: "rgba(221, 221, 221, 0.08)"  // Very light gray grid, barely visible
			}
		  },
		  y: {
			stacked: false,
			beginAtZero: true,
			ticks: {
			  color: "rgba(255, 255, 255, 0.75)", // White with 75% opacity
			},
			grid: {
			  display: true,
			  color: "rgba(221, 221, 221, 0.08)"  // Same as the X-axis grid color
			}
		  }
		}
	};
	
	const BrowserSharePieChart = () => {
		const data = {
		  labels: ['Chrome', 'Firefox', 'Internet Explorer', 'Edge', 'Safari', 'Other'],
		  datasets: [{
			data: [58.9, 13.29, 13, 3.78, 3.42, 7.61],
			backgroundColor: [
			  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
			],
			borderColor: '#fff',
			borderWidth: 2
		  }]
		};
	  
		const options = {
		  responsive: true,
		  maintainAspectRatio: false,
		  plugins: {
			legend: {
			  display: false
			},
			tooltip: {
			  callbacks: {
				label: function(tooltipItem) {
				  return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(1)}%`;
				}
			  }
			}
		  },
		  cutout: '50%',
		  rotation: -0.5 * Math.PI,  // -90 degrees
		  circumference: Math.PI,    // 180 degrees
		  animation: {
			animateRotate: true,
			animateScale: false
		  },
		  title: {
			display: true,
			text: 'Browser shares 2017',
			position: 'top'
		  },
		  layout: {
			padding: {
			  top: 0
			}
		  }
		};
	  
		return <Pie data={data} options={options} />;
	  };
	  
	  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL+`/dashboard`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setDashdata({botCount: response.data.botCount,conversationCount: response.data.conversationCount,messageCount:response.data.messageCount});
        console.log(response);
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
<div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
	<div className="col">
		<div className="card radius-10">
			<div className="card-body">
				<div className="d-flex align-items-center">
					<div>
						<p className="mb-0 text-white">Total Messages</p>
						<h4 className="my-1 text-white">{dashdata.messageCount}</h4>
						<p className="mb-0 font-13 text-white"><i className="bx bxs-up-arrow align-middle"></i>$34 from last week</p>
					</div>
					<div className="widgets-icons bg-light-transparent text-white ms-auto"><i className="bx bxs-message-square-dots"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="col">
		<div className="card radius-10">
			<div className="card-body">
				<div className="d-flex align-items-center">
					<div>
						<p className="mb-0 text-white">Active Bots</p>
						<h4 className="my-1 text-white">{dashdata.botCount}</h4>
						<p className="mb-0 font-13 text-white"><i className="bx bxs-up-arrow align-middle"></i>1.6K from last week</p>
					</div>
					<div className="widgets-icons bg-light-transparent text-white ms-auto"><i className="bx bxs-bot"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="col">
		<div className="card radius-10">
			<div className="card-body">
				<div className="d-flex align-items-center">
					<div>
						<p className="mb-0 text-white">Closed Conversations</p>
						<h4 className="my-1 text-white">{dashdata.conversationCount}</h4>
						<p className="mb-0 font-13 text-white"><i className="bx bxs-down-arrow align-middle"></i>2.4K from last week</p>
					</div>
					<div className="widgets-icons bg-light-transparent text-white ms-auto"><i className="bx bxs-conversation"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="col">
		<div className="card radius-10">
			<div className="card-body">
				<div className="d-flex align-items-center justify-content-between">
					<div>
						<p className="mb-0 text-white">Bounce Rate</p>
						<h4 className="my-1 text-white">34.46%</h4>
						<p className="mb-0 font-13 text-white"><i className="bx bxs-down-arrow align-middle"></i>12.2% from last week</p>
					</div>
					<div className="widgets-icons bg-light-transparent text-white"><i className="bx bx-line-chart-down"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div className="row">
	<div className="col-12 col-lg-8 col-xl-8 d-flex">
	   <div className="card radius-10 w-100">
		<div className="card-body">
			<div className="d-flex align-items-center">
				<div>
					<h5 className="mb-0">Bots Usage</h5>
				</div>
				<div className="dropdown options ms-auto">
					<div className="dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown">
					  <i className='bx bx-dots-horizontal-rounded'></i>
					</div>
					<ul className="dropdown-menu">
					  <li><a className="dropdown-item" href="javascript:;">Action</a></li>
					  <li><a className="dropdown-item" href="javascript:;">Another action</a></li>
					  <li><a className="dropdown-item" href="javascript:;">Something else here</a></li>
					</ul>
				  </div>
			</div>
			<div className="d-flex align-items-center ms-auto font-13 gap-2 my-3">
				<span className="border px-1 rounded cursor-pointer"><i className="bx bxs-circle me-1 text-white"></i>New Visitor</span>
				<span className="border px-1 rounded cursor-pointer"><i className="bx bxs-circle me-1 text-light-1"></i>Old Visitor</span>
			</div>
		   <div className="chart-container-1">
		   		<Line data={lineChartData} options={lineChartOptions} />
		   </div>
		</div>
		<div className="row row-cols-1 row-cols-md-3 row-cols-xl-3 g-0 row-group text-center border-top">
		  <div className="col">
			<div className="p-3">
			  <h5 className="mb-0">45.87M</h5>
			  <small className="mb-0">Overall Visitor <span> <i className="bx bx-up-arrow-alt align-middle"></i> 2.43%</span></small>
			</div>
		  </div>
		  <div className="col">
			<div className="p-3">
			  <h5 className="mb-0">15:48</h5>
			  <small className="mb-0">Visitor Duration <span> <i className="bx bx-up-arrow-alt align-middle"></i> 12.65%</span></small>
			</div>
		  </div>
		  <div className="col">
			<div className="p-3">
			  <h5 className="mb-0">245.65</h5>
			  <small className="mb-0">Pages/Visit <span> <i className="bx bx-up-arrow-alt align-middle"></i> 5.62%</span></small>
			</div>
		  </div>
		</div>
	   </div>
	</div>

	<div className="col-12 col-lg-4 col-xl-4 d-flex">
	
	   <div className="card radius-10 overflow-hidden w-100">
		  <div className="card-body">
			<div className="d-flex align-items-center mb-2">
				<div>
					<h5 className="mb-0">Weekly conversations</h5>
				</div>
				<div className="dropdown options ms-auto">
					<div className="dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown">
					  <i className='bx bx-dots-horizontal-rounded'></i>
					</div>
					<ul className="dropdown-menu">
					  <li><a className="dropdown-item" href="javascript:;">Websites</a></li>
					  <li><a className="dropdown-item" href="javascript:;">Social Media</a></li>
					  <li><a className="dropdown-item" href="javascript:;">Mobile App</a></li>
					</ul>
				  </div>
			</div>
			<div className="chart-js-container2">
			<div className="piechart-legend">
			  <h3 className="mb-1">95K</h3>
			  <h6 className="mb-0">Total conversations</h6>
			 </div>
			 <BrowserSharePieChart />
			 </div>
		  </div>
		  <div className="table-responsive">
			<table className="table align-items-center mb-0">
			  <tbody>
				<tr className="border-top">
				  <td><i className="bx bxs-circle text-white me-2"></i> Websites</td>
				  <td>$5856</td>
				  <td>+55%</td>
				</tr>
				<tr>
				  <td><i className="bx bxs-circle text-light-2 me-2"></i>Social Media</td>
				  <td>$2602</td>
				  <td>+25%</td>
				</tr>
				<tr>
				  <td><i className="bx bxs-circle text-light-3 me-2"></i>Mobile App</td>
				  <td>$1802</td>
				  <td>+15%</td>
				</tr>
			  </tbody>
			</table>
		  </div>
		</div>
	</div>
   </div>
   <div className="row row-cols-1 row-cols-lg-3">
	<div className="col">
	   <div className="card radius-10">
		 <div className="card-body">
		   <div className="d-flex align-items-center">
			 <div className="w_chart easy-dash-chart1" data-percent="60">
			   <span className="w_percent"></span>
			 </div>
			 <div className="ms-3">
			   <h6 className="mb-0">Website usage</h6>
			   <small className="mb-0">22.14% <i className='bx bxs-up-arrow align-middle me-1'></i>Since Last Week</small>
			 </div>
			 <div className="ms-auto fs-1 text-white"><i className='bx bxl-facebook'></i></div>
		   </div>
		 </div>
	   </div>
	 </div>
	 <div className="col">
	   <div className="card radius-10">
		 <div className="card-body">
		   <div className="d-flex align-items-center">
			 <div className="w_chart easy-dash-chart2" data-percent="65">
			   <span className="w_percent"></span>
			 </div>
			 <div className="ms-3">
				<h6 className="mb-0">Social Media usage</h6>
				<small className="mb-0">32.15% <i className='bx bxs-up-arrow align-middle me-1'></i>Since Last Week</small>
			  </div>
			  <div className="ms-auto fs-1 text-white"><i className='bx bxl-twitter'></i></div>
		   </div>
		 </div>
	   </div>
	 </div>
	 <div className="col">
	   <div className="card radius-10">
		 <div className="card-body">
		   <div className="d-flex align-items-center">
			 <div className="w_chart easy-dash-chart3" data-percent="75">
			   <span className="w_percent"></span>
			 </div>
			 <div className="ms-3">
				<h6 className="mb-0">Mobile App usage</h6>
				<small className="mb-0">58.24% <i className='bx bxs-up-arrow align-middle me-1'></i>Since Last Week</small>
			  </div>
			  <div className="ms-auto fs-1 text-white"><i className='bx bxl-youtube'></i></div>
		   </div>
		 </div>
	   </div>
	 </div>
	</div>
   </>
  );
};

export default Dashboard;
