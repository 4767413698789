import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaDesktop, FaHome, FaPlus } from 'react-icons/fa';
import { Tab, Nav } from 'react-bootstrap';
import CustomizationForm from './CustomizationForm';
import AdvancedCustomizationForm from './AdvancedCustomizationForm';
import BotPreview from '../integration/BotPreview';
import BotPreviewCreation from './BotPreviewCreation';
// import 'bootstrap/dist/css/bootstrap.min.css';

const ChatDesign = () => {
  const [bots, setBots] = useState([]);
  const [bot, setBot] = useState({});
  const [selectedBot, setSelectedBot] = useState('');
  const [customization, setCustomization] = useState({});
  const [customizations, setCustomizations] = useState({
  theme: 'light',
  color: '#007bff',
  position: 'bottom-right',
  iconcolor: '#007bff'
});
  const [selectedElement, setSelectedElement] = useState(null);
  const [elementStyles, setElementStyles] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;
  const [botData, setBotData] = useState({
    id: '',
    name: '',
    url: '',
    llm: 'gpt4',
    userId: '11',
    theme: 'light',
    status: 1,
    welcomeMsg: 'Here will be the welcome message.',
    color: '#007bff',
    personality: 'personality',
    language: 'language',
    position: 'bottom-right',
  });

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(API_URL + '/bots', {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
        });
        console.log('response.data[0]');
        console.log(response.data[0]);
        setBots(response.data);
        if (response.data.length > 0) {
          setSelectedBot(response.data[0].bot_id);
          setElementStyles(response.data[0]['elementStyles']);

          console.log('elementStyles');
          console.log(response.data[0]['elementStyles']);
          const elementStylesHash = response.data[0]['elementStyles'].reduce((acc, item) => (item.name === "bot-message" ? { [item.name]: item.template_data } : acc), {});

          setElementStyles(elementStylesHash)
          console.log('response.data[0].iconcolor ');    
          console.log(response.data[0].iconcolor );
          console.log('response.data[0].iconcolor ');    

          setBot(response.data[0]);
          setCustomizations(prev => ({
            ...prev,
            theme: response.data[0].theme || prev.theme,
            color: response.data[0].color || prev.color,
            position: response.data[0].position || prev.position,
            iconcolor: response.data[0].iconcolor || prev.iconcolor,
          }));
        }
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };
    fetchBots();
  }, [API_URL]);

  const handleBotSelectionChange = (e) => {
    const botId = e.target.value;
    setSelectedBot(botId);
    const selected = bots.find((b) => b.bot_id.toString() === botId);
    console.log(customizations.iconcolor);
    console.log('customizations.iconcolor');

    let tttt = bots.find((b) => b.bot_id.toString() === botId);
    console.log(tttt);
    
    console.log('handleBotSelectionChange');
    if (selected) {
      setBot(selected);
      setElementStyles(selected.elementStyles);
      setCustomizations({
        theme: selected.theme || 'light',
        color: selected.color || '#FFA500',
        position: selected.position || 'bottom-right',
        iconcolor: selected.iconcolor || '#FFA500',
      });

    }
  };

  const handleCustomizationChange = (e) => {
    const { name, value } = e.target;
    let tempElementStyles = {};

    
    setCustomizations((prev) => ({
      ...prev,
      [name]: value,
    }));
    if(name === 'theme' && value === 'dark'){
      tempElementStyles['user-message'] = {'backgroundColor':customizations.color,'color':'#000'};
      tempElementStyles['chat-header'] = {'backgroundColor':customizations.color,'color':'#fff'};
      tempElementStyles['bot-message'] = {'backgroundColor':"#384357",'color':'#fff'};
      tempElementStyles['conversation-background'] = {'backgroundColor':'#151921','color':'#fff'};
      tempElementStyles['text-input'] = {'backgroundColor':'#384357','color':'#fff'};
      tempElementStyles['send-btn'] = {'backgroundColor':customizations.color,'color':'#fff'}; 
    } else if (name === 'theme' && value === 'light'){
      tempElementStyles['user-message'] = {'backgroundColor':customizations.color,'color':'#fff'};
      tempElementStyles['chat-header'] = {'backgroundColor':customizations.color,'color':'#fff'};
      tempElementStyles['bot-message'] = {'backgroundColor':'#fff','color':'#000'};
      tempElementStyles['conversation-background'] = {'backgroundColor':'#fff','color':'#000'};
      tempElementStyles['text-input'] = {'backgroundColor':'#fff','color':'#000'};
      tempElementStyles['send-btn'] = {'backgroundColor':customizations.color,'color':'#fff'} ;         
    }
    setElementStyles(tempElementStyles);

    console.log("bot.name");
    console.log(customizations,"--------------------------------------");
    // console.log(value);
    console.log('vaasdfasdfsdflue');
  };

  const handleElementStyleChange = (e) => {
    const { name, value } = e.target;
    setCustomization({name:value});
    setElementStyles((prev) => ({
      ...prev,
      [selectedElement]: {
        ...prev[selectedElement],
        [name]: value,
      },
    }));
  };

  const handleElementClick = (element) => {
    setSelectedElement(element);
  };

  const handleSaveCustomizations = async (formname) => {
    try {
      console.log(formname);
      console.log('elementStyleselementStyleselementStyleselementStyles');
      console.log(elementStyles);
      let es = {elementStyles: elementStyles} 
      const response = await axios.put(
        API_URL + `/bots/${selectedBot}/customizations`,
        { ...customizations, elementStyles,formname},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
        }
      );
      setBots(prevBots => 
        prevBots.map(b => 
          b.bot_id === bot.bot_id 
            ? { ...b, elementStyles: elementStyles, iconcolor: customizations.iconcolor, iconStyle: {'color':customizations.iconcolor,'fill':customizations.iconcolor} } 
            : b
        )
      );
      console.log(response.data.message);
    } catch (error) {
      console.error('Error saving customizations:', error);
    }
  };

  return (
    <>
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">Design</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item"><a href="javascript:;"><FaDesktop className="bx bx-home-alt" /></a></li>
              <li className="breadcrumb-item active" aria-current="page">Chat</li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />
      <div className="card">
        <div className="card-body"> 
        <div className="row">

           <div className='col-md-6'>

              <Tab.Container id="left-tabs-example" defaultActiveKey="standard">
                <Nav variant="pills" className="flex-row mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="standard">
                      <div className="d-flex align-items-center">
                        <div className="tab-icon"><FaHome /></div>
                        <div className="tab-title ml-1">Basic</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="advanced">
                      <div className="d-flex align-items-center">
                        <div className="tab-icon"><FaPlus /></div>
                        <div className="tab-title ml-1">Advanced</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                  <Tab.Content>
                  <Tab.Pane eventKey="standard">
                      <CustomizationForm
                        bots={bots}
                        selectedBot={selectedBot}
                        customizations={customizations}
                        handleBotSelectionChange={handleBotSelectionChange}
                        handleCustomizationChange={handleCustomizationChange}
                        handleSaveCustomizations={handleSaveCustomizations}
                      />
                  </Tab.Pane>
                  <Tab.Pane eventKey="advanced">
                    <AdvancedCustomizationForm
                      selectedElement={selectedElement}
                      elementStyles={elementStyles[selectedElement] || {}}
                      handleElementStyleChange={handleElementStyleChange}
                      handleSaveCustomizations={handleSaveCustomizations}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
          </div>
          <div className="col-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center p-5">
              {selectedBot && (
                <>
                  <BotPreviewCreation
                    bot={bot}
                    theme={customizations.theme}
                    color={customizations.color}
                    elementStyles={elementStyles || {}}
                    position={customizations.position}
                    botData={botData}
                    handleElementClick={handleElementClick}
                  />
                  <BotPreview
                    bot={bot}
                    theme={customizations.theme}
                    color={customizations.color}
                    iconStyle={{'fill':customizations.iconcolor,'color':customizations.iconcolor}}
                    position={customizations.position}
                    elementStyles={elementStyles || {}}
                  />
                </>
              )}
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default ChatDesign;
