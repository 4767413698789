import React, { useEffect, useRef } from "react";
import Splitting from "splitting"; // Ensure you have the Splitting library installed and imported

const Split = ({ children }) => {
  const target = useRef(null);

  useEffect(() => {
    if (target.current) {
      Splitting({ target: target.current });
    }
  }, [children]);

  return <div ref={target}>{children}</div>;
};

export default Split;
