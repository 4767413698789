import React from 'react';
import {FaArrowRight} from 'react-icons/fa';
const AdvancedCustomizationForm = ({ selectedElement, elementStyles, handleElementStyleChange, handleSaveCustomizations }) => {
  if (!selectedElement) {
    return         <div style={{
        height: '100%',
        fontSize: '30px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
        paddingTop: '20%',
        paddingBottom: '20%',
        color: 'rgba(255, 255, 255, 0.2)',
        textAlign: 'center',
    }}>
        Click an element<br />to customize<br/><FaArrowRight style={{fontSize:'70px'}}/>
    </div>;
  }
  console.log(selectedElement, elementStyles, handleElementStyleChange, handleSaveCustomizations );
  return (
    <div className="col-12 col-lg-12 h-100 d-flex flex-column justify-content-left p-5" style={{color: 'white !important'}}>
      <h3>Customize .{selectedElement}</h3>
      <div>
        <label>Background Color:</label>
        <input type="color" name="backgroundColor" value={elementStyles.backgroundColor || ''} onChange={handleElementStyleChange} className='form-control form-control-lg' />
      </div>
      <div>
        <label>Color:</label>
        <input type="color" name="color" value={elementStyles.color || ''} onChange={handleElementStyleChange} className='form-control form-control-lg' />
      </div>
      <div>
        <label>Font Size:</label>
        <input type="number" name="fontSize" value={elementStyles.fontSize || '1'} onChange={handleElementStyleChange} className='form-control form-control-lg' step="1"/>
      </div>
      <button onClick={() => handleSaveCustomizations('advanced')} className="save-customizations-btn btn btn-light btn-lg mt-5">
        Save Customizations
      </button>
    </div>
  );
};

export default AdvancedCustomizationForm;
