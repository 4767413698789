import React, { useState, useEffect, useRef } from 'react';
import {FaComments,FaRobot, FaPlus} from 'react-icons/fa';
import axios from 'axios';

const Chat = () => {
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState("Select a bot ");
    const [bot, setBot] = useState({});
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [conversationId, setConversationId] = useState();
    const [conversations, setConversations] = useState([]);
    const messagesEndRef = useRef(null);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
      if (messagesEndRef.current) {
        requestAnimationFrame(() => {
          messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        });
      }
    }, [messages]);
    
  
   // Function to send a message
const sendMessage = async () => {
    if (inputValue.trim() === '') return;
    let temop_conv_id = '';
    try {
      const newMessage = { sender: 'user', content: inputValue, created_at: 'now' };
      setMessages([...messages, newMessage]);
      setInputValue('');
  
      setIsBotTyping(true);
        console.log("messageData");
        console.log(localStorage);
        const token = localStorage.getItem('userToken'); // Get token from local storage
        const messageData = { conversation_id: conversationId, sender: "user", content: inputValue, receiver: 'bot', bot_id: bot.bot_id };
        console.log("messageData");
        console.log(messageData);


        const response = await axios.post(API_URL+'/messages', messageData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Extract conversation and message from the response
        const { conversation, message, botMessage } = response.data;

        // Update state with conversation ID if it's not already set
        setConversationId(conversation);
        temop_conv_id = conversation.id
        console.log(conversation.id)
        console.log(conversationId)
        console.log(conversation.id)

        // if (!conversationId) {
            // setConversationId(conversation.id);
        // }


        // Update local messages state with bot message
        setMessages((currentMessages) => [...currentMessages, {'sender': 'bot', 'content':botMessage,'created_at':'now'}]);
        setIsBotTyping(false);

    } catch (error) {
        console.error('Failed to send message:', error);
        // Handle errors, such as displaying a message to the user
    }

    // Update local messages state with user message


    // Simulate bot response
    // setTimeout(async () => {
    //     try {
    //         const token = localStorage.getItem('userToken'); // Get token from local storage
    //         const messageData = { conversation_id: temop_conv_id, sender: 'bot', content: 'This is a sample response.', receiver: 'user' ,bot_id: bot.bot_id};
    //         console.log(messageData);
    //         const response = await axios.post(API_URL+'/messages', messageData, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });

    //         console.log(response.data); // Log or handle the response data

    //         // Extract message from the response
    //         const { message } = response.data;

    //         // Update local messages state with bot message
    //         setMessages((currentMessages) => [...currentMessages, message]);
    //         setIsBotTyping(false);

    //     } catch (error) {
    //         console.error('Failed to receive bot response:', error);
    //         // Handle errors, such as displaying a message to the user
    //     }
    // }, 1000); // Simulate bot typing delay
};



    useEffect(() => {
        const fetchBots = async () => {
          try {
            const response = await axios.get(API_URL+'/bots', {
              headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
            });
            setBots(response.data);
            if (response.data.length > 0) {
              // Assuming the first bot should be selected by default
            //   setSelectedBot(response.data[0].name);
              // Set default customizations if the bot doesn't have any
            }
          } catch (error) {
            console.error('Error fetching bots: s', error);
          }
        };
        const fetchConversations = async () => {
            try {
              const response = await axios.get(API_URL+'/bot/'+bot.bot_id+'/conversations', {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
              });
              let temp_conversations = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
              setConversations(temp_conversations);
              console.log('temp_conversations')
              console.log(temp_conversations)
              if (response.data.length > 0) {
                // Assuming the first bot should be selected by default
              //   setSelectedBot(response.data[0].name);
                // Set default customizations if the bot doesn't have any
              }
            } catch (error) {
              console.error('Error fetchMessages :', error);
            }
          };
        fetchBots();
        fetchConversations();
      }, [messages]);
      useEffect(() => {
        const fetchMessages = async () => {
          try {
            const response = await axios.get(API_URL+'/bots', {
              headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
            });
            setBots(response.data);
            if (response.data.length > 0) {
              // Assuming the first bot should be selected by default
            //   setSelectedBot(response.data[0].name);
              // Set default customizations if the bot doesn't have any
            }
          } catch (error) {
            console.error('Error fetching bots: s', error);
          }
        };
        fetchMessages();
    }, []);
    const changeBot = (bot_name,bot_obj) => {
        setSelectedBot(bot_name);
        setBot(bot_obj);
    };
    const newConversation = (bot_name,bot_obj) => {
      setSelectedBot(bot_name);
      setBot(bot_obj);
      setMessages([])
      setConversationId(null)
  };
    const changeConversation =  async (newConversationId,newbot) => {
      console.log("looool",newConversationId);
        try {
            const response = await axios.get(API_URL+'/bot/'+bot.bot_id+'/conversations/'+newConversationId, {
              headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
            });
            console.log(response.data);
            console.log("response.data");
            console.log("response.data");
            console.log("response.data");
            console.log("response.data");
            console.log(response.data);
            const modifiedData = response.data.map(message => ({
              ...message,
              receiver: message.receiver === '1' ? "user" : message.receiver,
              sender: message.sender === '1' ? "user" : message.sender,
              created_at: message.created_at
            }));
            
            // Set modified data to messages state
            setMessages(modifiedData);
            if (response.data.length > 0) {
              // Assuming the first bot should be selected by default
            //   setSelectedBot(response.data[0].name);
              // Set default customizations if the bot doesn't have any
            }
          } catch (error) {
            console.error('Error fetching bots: s', error);
          }
        
          setSelectedBot(newbot.name);
          changeBot(newbot.name,newbot)
        setConversationId(newConversationId);
    };
    const formatUpdatedAt = (updatedAt) => {
      const updatedDate = new Date(updatedAt);
      const now = new Date();
    
      return updatedDate.getDate() === now.getDate() &&
        updatedDate.getMonth() === now.getMonth() &&
        updatedDate.getFullYear() === now.getFullYear()
        ? `${updatedDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`
        : updatedDate.getDate() === now.getDate() - 1 &&
          updatedDate.getMonth() === now.getMonth() &&
          updatedDate.getFullYear() === now.getFullYear()
        ? `Yesterday`
        : updatedDate.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });
    };
  return (
    <>
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="breadcrumb-title pe-3">Chat</div>
            <div class="ps-3">
                <nav aria-label="breadcrumb">                    
                <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item"><a href="javascript:;"><FaComments className="bx bx-home-alt" /></a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Playground {conversationId}</li>
                </ol>
                </nav>
            </div>
        </div>
        <hr/>
        <div className="chat-wrapper" style={{height:'80vh'}}>
            <div className="chat-sidebar">
                <div className="chat-sidebar-header">
                  <div className="dropdown mt-3"> 
                      <a href="#" className="btn btn-light btn-sm radius-30 dropdown-toggle dropdown-toggle-nocaret w-100"  data-bs-toggle="dropdown">
                        <FaPlus className='bx bx-video me-2' /> New conversation<i className='bx bxs-chevron-down ms-2'></i>
                      </a>
                      <div className="dropdown-menu w-100 overflow-scroll" style={{height: '455px'}}> 
                        <div style={{height: 'auto'}}>
                          {bots.map((b) => (
                            <a key={b.bot_id} className="dropdown-item w-100" href="#"  onClick={() => newConversation(b.name,b)}> {b.name}</a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3"></div>
                    <div className="input-group input-group-sm"> 
                        <span className="input-group-text"><i className='bx bx-search'></i></span>
                        <input type="text" className="form-control" placeholder="Conversations & messages" /> 
                        <span className="input-group-text"><i className='bx bx-dialpad'></i></span>
                    </div>
                </div>
                <div className="chat-sidebar-content">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-Chats">
                            <div className="p-3">
                                
                                <div className="dropdown mt-3"> <a href="#" className="text-uppercase text-secondary dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown">Recent Chats <i className='bx bxs-chevron-down'></i></a>
                                    <div className="dropdown-menu">    
                                        <a className="dropdown-item" href="#">Recent Chats</a>
                                        <a className="dropdown-item" href="#">Hidden Chats</a>
                                        <div className="dropdown-divider"></div>    
                                        <a className="dropdown-item" href="#">Sort by Time</a>
                                        <a className="dropdown-item" href="#">Sort by Unread</a>
                                        <div className="dropdown-divider"></div>    
                                        <a className="dropdown-item" href="#">Show Favorites</a>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-list  overflow-scroll" style={{height: '100vh'}}>
                                <div className="list-group list-group-flush ">
                                  {/* Conversation */}
                                  <div className='overflow-scroll' style={{height: '420px'}}>
                                    {conversations.map((conversation) => (

                                      <a key={conversation.id}  href="#" onClick={() => changeConversation(conversation.id,bots.find(bot => bot.bot_id === conversation.bot_id))} className="list-group-item">
                                          <div className="d-flex">
                                              <div className="chat-user-online">
                                                  <img src="assets/images/avatars/avatar-2.png" width="42" height="42" className="rounded-circle" alt="" />
                                              </div>
                                              <div className="flex-grow-1 ms-2">
                                                  <h6 className="mb-0 chat-title">{conversation.bot_name}</h6>
                                                  <p className="mb-0 chat-msg">{conversation.content.length > 25 ? conversation.content.substring(0, 25) + '...' : conversation.content}</p>
                                              </div>
                                              <div className="chat-time">{formatUpdatedAt(conversation.updated_at)}</div>
                                          </div>
                                      </a>

                                      ))}
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            { selectedBot !== 'Select a bot ' && 
            <div className="chat-header d-flex align-items-center">
                <div className="chat-toggle-btn"><i className='bx bx-menu-alt-left'></i>
                </div>
                <div>
                    <h4 className="mb-1 font-weight-bold">{selectedBot}</h4>
                    <div className="list-inline d-sm-flex mb-0 d-none"> 
                        <a href="#" className="list-inline-item d-flex align-items-center"><small className='bx bxs-circle me-1 chart-online'></small></a>
                        <a href="#" className="list-inline-item d-flex align-items-center">|</a>
                        <a href="#" className="list-inline-item d-flex align-items-center"><i className='bx bx-images me-1'></i>Media</a>
                        <a href="#" className="list-inline-item d-flex align-items-center">|</a>
                        <a href="#" className="list-inline-item d-flex align-items-center"><i className='bx bx-search me-1'></i>Find</a>
                    </div>
                </div>
            </div> }
            <div className="chat-content overflow-scroll" style={{height: '625px'}} ref={messagesEndRef}>

              {selectedBot !== 'Select a bot ' ? (
                // If selectedBot is not 'Select a bot ', render conversation messages
                <>
                  {/* Conversation messages */}
                  {messages.map((message, index) => (
                    <div key={index} className={message.sender !== 'bot' ? 'chat-content-rightside' : 'chat-content-leftside'} >
                      <div className="d-flex">
                        {message.sender === 'bot' && <img src="assets/images/avatars/avatar-3.png" width="48" height="48" className="rounded-circle" alt="" />}
                        <div className="flex-grow-1 ms-2">
                          <p className={message.sender !== 'bot' ? 'mb-0 chat-time  text-end' : 'mb-0 chat-time'}>{message.sender !== 'bot' ? 'You' : selectedBot}, {formatUpdatedAt(message.created_at) !== 'Invalid Date' ? formatUpdatedAt(message.created_at) : message.created_at }</p>
                          <p className={message.sender !== 'bot' ? 'chat-right-msg' : 'chat-left-msg'}>{message.content}</p>
                        </div>
                        {message.sender !== 'bot' && <img src="assets/images/avatars/avatar-3.png" width="48" height="48" className="rounded-circle" alt="" />}
                      </div>
                    </div>
                  ))}
                  {isBotTyping &&  <div key={999999} className='chat-content-leftside' >
                      <div className="d-flex">
                         <img src="assets/images/avatars/avatar-3.png" width="48" height="48" className="rounded-circle" alt="" />
                        <div className="flex-grow-1 ms-2">
                          <p className='mb-0 chat-time'>{selectedBot}, Now</p>
                          <p className='chat-left-msg'><div className="spinner-grow" role="status"> <span className="visually-hidden">Loading...</span>
								</div></p>
                        </div>
                      </div>
                    </div>}
                </>
              ) : (
                // If selectedBot is 'Select a bot ', render bot selection UI
                <div className="d-flex align-items-center justify-content-center" style={{ width: '100%', height: '100%' }}>

                <div className="d-flex flex-column align-items-center">
    
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">Choose a bot to start a conversation with.</p>
                  </div>
                  {/* <div className="dropdown">
                    <div className="cursor-pointer font-24 dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown"><i className='bx bx-dots-horizontal-rounded'></i></div>
                    <div className="dropdown-menu dropdown-menu-end"> 
                      <a className="dropdown-item" href="#">Settings</a>
                      <div className="dropdown-divider"></div>    
                      <a className="dropdown-item" href="#">Help & Feedback</a>
                      <a className="dropdown-item" href="#">Enable Split View Mode</a>
                      <a className="dropdown-item" href="#">Keyboard Shortcuts</a>
                      <div className="dropdown-divider"></div>    
                      <a className="dropdown-item" href="#">Sign Out</a>
                    </div>
                  </div> */}

                  <div className="dropdown mt-3"> 
                    <a href="#" className="btn btn-light btn-sm radius-30 dropdown-toggle dropdown-toggle-nocaret w-100"  data-bs-toggle="dropdown">
                      <FaRobot className='bx bx-video me-2' />Select a bot<i className='bx bxs-chevron-down ms-2'></i>
                    </a>
                    <div className="dropdown-menu w-100 overflow-scroll" style={{height: '455px'}}> 
                      <div style={{height: 'auto'}}>
                        {bots.map((b) => (
                          <a key={b.bot_id} className="dropdown-item w-100" href="#"  onClick={() => changeBot(b.name,b)}> {b.name}</a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              )}


            </div>
            { selectedBot !== 'Select a bot ' && 
            <div className="chat-footer d-flex align-items-center">
                <div className="flex-grow-1 pe-2">
                    <div className="input-group">    
                        <span className="input-group-text"><i className='bx bx-smile'></i></span>
                        <textarea
                            className='form-control'
                            placeholder="Type a message..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                          />
                    </div>
                </div>
                <div className="chat-footer-menu"> 
                    <a href="#" onClick={sendMessage} className="list-inline-item d-flex align-items-center">Send</a>
                </div>
            </div>}
            <div className="overlay chat-toggle-btn-mobile"></div>
        </div>
    </>
  );
};

export default Chat;
