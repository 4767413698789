import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBook } from 'react-icons/fa';

const KnowledgeBaseList = () => {
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchKnowledgeBases = async () => {
      try {
        const response = await axios.get(API_URL+'/knowledge-bases', {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
        });
        setKnowledgeBases(response.data);
      } catch (error) {
        console.error('Error fetching knowledge bases:', error);
      }
    };

    fetchKnowledgeBases();
  }, []);

  return (
    <>
    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div class="breadcrumb-title pe-3">Bots </div>
      <div class="ps-3">
        <nav aria-label="breadcrumb">                    
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><a href="javascript:;"><FaBook className="bx bx-home-alt" /></a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Knowledge Base </li>
          </ol>
        </nav>
      </div>
    </div>
    <hr/>
    <div className="card">
        <div className="card-body">
          <div className='row'>
            <div className='col-md-12 d-flex flex-column justify-content-center p-5 text-center'>
                <figure className="">
                  <blockquote className="blockquote">
                    <h1>Knowledge</h1>
                    <h5> Explained Simply </h5>
                    <h5>Imagine you have a super smart helper, assistant, commercial.. etc  who knows everything about your website, your business, product... etc and how it works. That's what the knowledge is like in here</h5>
                    <ul className="list-style-none p-4 text-white rounded">
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>01</h2> </li>
                      <li>Smart Guidance</li>
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>02</h2> </li>
                      <li>Expert Assistance</li>
                      <li><h2 className='widgets-icons rounded-circle mx-auto mb-3 text-dark bg-light bg-gradient '>03</h2> </li>
                      <li>Preview Before You Commit</li>
                    </ul>
                  </blockquote>
                  <figcaption className="blockquote-footer"><cite title="Support">Support</cite>
                  </figcaption>
                </figure>
            </div>
        </div>
      </div>
    </div>
    <div>
      <h2>aKnowledge Base Management <Link to="/knowledge-base/create">Add</Link></h2>
      {knowledgeBases.map((kb) => (
        <div key={kb.id}>
          <h3>{kb.title}</h3>
          <p>{kb.content}</p>
          {/* Display bot name using kb.botId */}
          <p>Bot: {kb.botName}</p>
        </div>
      ))}
    </div>
    </>
  );
};

export default KnowledgeBaseList;
