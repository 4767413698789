import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the import path as necessary
import logoIcon from '../assets/images/logo-icon.png'
import '../assets/css/Login.css';
import CallToAction from './CallToAction';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth(); // Use the login function from AuthContext
  const navigate = useNavigate(); // Hook for programmatically navigating
  const API_URL = process.env.REACT_APP_API_URL;
  console.log("API URL");
  console.log(API_URL);
  const handleLogin = async (e) => {
    e.preventDefault();
    try { 
      const { data } = await axios.post(API_URL+'/login', {
        username,
        password,
      });
      localStorage.setItem('userToken', data.token); // Store the token
      login(); // Update the global auth state to reflect that the user is logged in
      window.location.href = '/dashboard';
    } catch (err) {
      setError('Failed to login');
      console.error(err);
    }
  };

  return (
    <div className="section-authentication-cover w-100">
        <div className="row g-0">
          <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
              <CallToAction />
                <img src="assets/images/login-images/login-cover.svg" className="img-fluid auth-img-cover-login" width="650" alt=""/>
          </div>
          <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right login-bg align-items-center justify-content-center">
            <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
              <div className="card-body p-sm-5">
                <div className="mb-3 text-center ">
                  <img src={logoIcon} width="60" alt=""/>
                </div>
                <div className="text-center mb-4">
                  <h1 className="logo-text-login">BOTGROUND</h1>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <form className="form-body row g-3" onSubmit={handleLogin}>
                  <div className="col-12">
                    <input
                      type="text"
                      placeholder='Username'
                      value={username}
                      className="form-control"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="password"
                      placeholder='**********'
                      value={password}
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"/>
                      <label className="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                    </div>
                  </div>
                  <div className="col-md-6 text-end">	<a href="auth-cover-forgot-password.html">Forgot Password ?</a>
                  </div>
                  <div className="col-12">
                    <div className="d-grid">
                      <button type="submit" className="btn btn-light">Sign in</button>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="text-center">
                      <p className="mb-0">Don't have an account yet? <a href="/signup">Sign up here</a>
                      </p>
                    </div>
                  </div> */}
                </form>
                </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;