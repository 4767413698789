import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CreateKnowledgeBaseEntry from './CreateKnowledgeBaseEntry';
import { useParams } from 'react-router-dom';
import { FaArchive, } from 'react-icons/fa';

const KnowledgeBaseManagement = () => {
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const { botId } = useParams(); // Access botId from route parameters
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchKnowledgeBases = async () => {
      try {
        const response = await axios.get(API_URL+`/knowledge-base/${botId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setKnowledgeBases(response.data);
      } catch (error) {
        console.error('Error fetching knowledge bases:', error);
      }
    };

    if (botId) {
      fetchKnowledgeBases();
    }
  }, []);
  const addKnowledgeBaseEntry = (newEntry) => {
    setKnowledgeBases((prevKnowledgeBases) => [...prevKnowledgeBases, newEntry]);
  };
  const deleteKnowledge = async (kbId) => {
    if (window.confirm("Are you sure you want to delete knowledge base : " + kbId)) {
      try {
        const response = await axios.delete(`${API_URL}/knowledge-base/${kbId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setKnowledgeBases(knowledgeBases.filter(kb => kb.kb_id !== kbId));
        alert(`KB DELETED`);
      } catch (error) {
        console.error('Error deleting knowledge base:', error);
      }
    }
  };
  
  const renderTimelineItem = (kb, index) => {
    const isEven = index % 2 === 0;
    return (
      <div key={kb.kb_id} className={`row g-0 ${isEven ? '' : 'flex-row-reverse'}`}>
        <div className="col-sm">
          {/* Spacer for alignment */}
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          {/* Center dot */}
          <div className="row h-50">
            <div className="col border-end">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-2">
            <span className="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-end">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className={`col-sm py-2 ${isEven ? '' : 'order-sm-first'}`}>
          <div className="card radius-15">
            <div className="card-body">
              <div className="float-end small">Date Placeholder</div>
              <h4 className="card-title text-white">{kb.title}</h4>
              <p className="card-text">{kb.content}</p>
              <p className="card-text">{kb.file_path}x</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div class="breadcrumb-title pe-3">Bot Library</div>
      <div class="ps-3">
        <nav aria-label="breadcrumb">                    
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><a href="javascript:;"><FaArchive className="bx bx-home-alt" /></a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Knowledge for </li>
          </ol>
        </nav>
      </div>
    </div>
    
    <div className="container py-2">
      <h2 className="font-weight-light  text-center text-white py-3">Knowledge Base Timeline</h2>
      {knowledgeBases.length > 0 ? (
        knowledgeBases.map((kb, index) => (
          <div key={kb.kb_id} className={`row g-0 ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
            <div className="col-sm py-2">
            <div className={`card delayed-transition ${index % 2 === 0 ? 'border-primary shadow' : ''} radius-15 ${kb.title.toLowerCase().trim() === 'start' || kb.title.toLowerCase().trim() === 'initbot' || kb.title.toLowerCase().trim() === 'update' ? 'bg-success' : ''}`}>
                <div className="card-body">
                <a className="float-end small" style={{ cursor: 'pointer',zIndex:'1000' }} onClick={() => deleteKnowledge(kb.kb_id)}>X</a>

                  <h4 className="card-title">{kb.title} </h4>{kb.title.toLowerCase() === 'start' || kb.title.toLowerCase() === 'initbot' || kb.title.toLowerCase() === 'update'  ? <span class="badge bg-primary">pre-defined</span> : ''}
                  <p className="card-text">{kb.content}</p>
                  <p className="card-text">{ kb.file_path && <span>Knowledge: <a href={kb.file_path} target="_blank">Download</a></span>}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
              <div className="row h-50">
                <div className="col border-end">&nbsp;</div>
                <div className="col">&nbsp;</div>
              </div>
              <h5 className="m-2">
                <span className={`badge delayed-transition rounded-pill ${kb.title.toLowerCase() === 'start' || kb.title.toLowerCase() === 'initbot' || kb.title.toLowerCase() === 'update' ? 'bg-success' : 'bg-light'} border`}>&nbsp;</span>
              </h5>
              <div className="row h-50">
                <div className="col border-end">&nbsp;</div>
                <div className="col">&nbsp;</div>
              </div>
            </div>
            <div className="col-sm">
              {/* Spacer for alignment */}
            </div>
          </div>
        ))
      ) : (
        <p>No knowledge base entries found for bot {botId}.</p>
      )}
     <div className={`row g-0 ${knowledgeBases.length % 2 != 0 ? '' : 'flex-row-reverse'}`}>
        <div className="col-sm py-2">
          <div className={`card ${knowledgeBases.length % 2 != 0 ? '' : 'border-primary shadow'} radius-15`}>
            <div className="card-body">
                <CreateKnowledgeBaseEntry botId={botId} addEntry={addKnowledgeBaseEntry} />
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-end">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-2">
            <span className="badge rounded-pill bg-white border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-end">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm">
          {/* Spacer for alignment */}
        </div>
      </div>
    </div>
    </>

  );
};

export default KnowledgeBaseManagement;
